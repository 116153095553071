import { useState, useCallback, useContext } from 'react';

import { plainToClass } from 'class-transformer';

import { ProductName } from '@airhelp/plus';

import { StripePaymentContext, TrackingContext } from 'contexts';
import { PaymentDetails } from 'models/PaymentDetails';
import { type CurrencyCode } from 'stores/preferences/currencies';

import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';
import useApplyPromoCode from 'hooks/api/useApplyPromoCode';
import { sentryUtils } from 'utils';

const usePromoCode = ({
  productName,
  currency,
}: {
  productName: ProductName;
  currency: CurrencyCode;
}) => {
  const promoCodeApi = useApplyPromoCode();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [promoCodeUsed, setPromoCodeUsed] = useState(false);
  const { checkoutDataDispatch } = useContext(TrackingContext);
  const { setPaymentDetails, paymentIntentId, paymentDetails } =
    useContext(StripePaymentContext);

  const onSubmit = useCallback(
    async (data: { promoCode: string }) => {
      const { promoCode } = data;

      if (promoCode === '' || promoCodeUsed || !paymentIntentId) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await promoCodeApi.mutateAsync({
          promoCode,
          productName,
          paymentIntentId,
          currency,
        });
        checkoutDataDispatch({
          type: CheckoutActionTypes.UPDATE,
          payload: { promoCode: data.promoCode },
        });
        setPaymentDetails(plainToClass(PaymentDetails, response));
      } catch (error) {
        setApiError(true);
        sentryUtils.captureException(error);
      } finally {
        setIsLoading(false);
        setPromoCodeUsed(true);
      }
    },
    [
      setIsLoading,
      setApiError,
      setPromoCodeUsed,
      setPaymentDetails,
      promoCodeUsed,
      paymentIntentId,
      paymentDetails,
    ],
  );

  return {
    isLoading,
    apiError,
    onSubmit,
    setPromoCodeUsed,
    promoCodeUsed,
    setApiError,
  };
};

export default usePromoCode;
