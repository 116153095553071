import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box, Text } from '@chakra-ui/react';
import { keyBy } from 'lodash';
import cookie from 'react-cookies';

import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import PayoutForm from 'components/Journey/AirBundle/Payment/PayoutForm';

import {
  airPayoutPaymentReducer,
  AirPayoutPaymentTypes,
} from 'reducers/airPayoutPaymentReducer/airPayoutPaymentReducer';
import { sentryUtils } from 'utils';

import DocumentsDetails from './DocumentsDetails';
import {
  AirBundleDocumentParam,
  BankFieldsList,
  InsuranceType,
  JourneyWithMeta,
} from '@airhelp/plus';
import { setVeriffUrl } from 'utils/veriff';
import { TURNSTILE_COOKIE } from 'config/cookies';
import { useTrackEvent } from 'utils/tracking/hooks';
import { anyDetailsFormInvalid } from 'utils/airBundle/airBundle';
import { AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import { Journey } from 'models/journey/Journey';
import useTriggerAirPayoutJourneys from 'hooks/api/airBundle/useTriggerAirPayoutJourneys';
import useUploadAirBundleDocument from 'hooks/api/airBundle/useUploadAirBundleDocument';

const insuranceType = InsuranceType.AIR_PAYOUT;

interface IComponent {
  journeyListData: AirPayoutJourney[];
  onTriggerSuccessful: (airRequestId?: number) => void;
  journeyData: Journey;
}

const PayoutPaymentView: React.FC<IComponent> = ({
  journeyListData,
  onTriggerSuccessful,
  journeyData,
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const triggerPayout = useTriggerAirPayoutJourneys();
  const uploadDocument = useUploadAirBundleDocument();
  const { id: journeyId } = journeyData;

  const airPayoutEligibleCanceled =
    journeyListData[0]?.airPayoutEligibleCanceled;

  const form = useForm({ mode: 'onSubmit' });

  const initialState = { airPayoutJourneys: [] };
  const [reducerData, dispatch] = useReducer(
    airPayoutPaymentReducer,
    initialState,
  );

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [airRequestId, setAirRequestId] = useState<number | undefined>();
  const [dataDetailsCollected, setDataDetailsCollected] = useState(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDocumentsFinished, setUploadDocumentsFinished] =
    useState<BankFieldsList | null>(null);
  const [isValidDataDetailsForm, setIsValidDataDetailsForm] = useState({});

  const setFormsValidity = (id, s) => {
    setIsValidDataDetailsForm({ ...isValidDataDetailsForm, [id]: s });
  };

  const isMultiPax = journeyListData.length > 1;
  const beneficiaryFullName = journeyListData[0]?.name;

  const reducerDataById = keyBy(reducerData.airPayoutJourneys, 'id');

  const availablePayoutRequestedPax = journeyListData.filter(
    (pax) => pax.payoutAllowed,
  ).length;
  useEffect(() => {
    if (airRequestId && !submitError) {
      onTriggerSuccessful(airRequestId);
    }
  }, [airRequestId, submitError]);

  const handleError = (error: unknown) => {
    setSubmitError(true);
    setUploadInProgress(false);
    sentryUtils.captureException(error);
  };

  const isDisabled =
    reducerData.airPayoutJourneys.length != availablePayoutRequestedPax ||
    anyDetailsFormInvalid(isValidDataDetailsForm);

  const savePayout = async (data: BankFieldsList) => {
    const token = cookie.load(TURNSTILE_COOKIE);

    const params = {
      countryId: data.country?.value || '',
      journeyId,
      token,
      bankFields: { ...data, countryOfResidence: data?.residence?.value },
      airPayoutJourneys: reducerData.airPayoutJourneys,
    };

    triggerPayout.mutate(params, {
      onError: (error) => {
        handleError(error);
      },
      onSuccess: (data: JourneyWithMeta) => {
        if (data.meta?.airRequestId) {
          const airRequestId = data.meta.airRequestId;
          setVeriffUrl(
            journeyData.id,
            data.meta.sessionUrl,
            insuranceType,
            airRequestId,
          );
          setAirRequestId(airRequestId);
        } else {
          setAirRequestId(-1);
        }
        setUploadInProgress(false);
        trackPageInteractions('airPayout paid', 'ahp airPayout page');
      },
    });
  };

  const onSubmit = async (data: BankFieldsList) => {
    setSubmitError(false);
    setUploadInProgress(true);
    const promises = reducerData.airPayoutJourneys.map((pax) => {
      const {
        bookingReferenceFile,
        passportOrIdFile,
        cancelationFile,
        boardingPassFile,
        id,
      } = pax;
      const resourceId = journeyId;

      if (airPayoutEligibleCanceled) {
        if (!bookingReferenceFile || !passportOrIdFile || !cancelationFile) {
          return Promise.reject(new Error('Missing required files'));
        }

        const booking = uploadDocument.mutateAsync({
          file: bookingReferenceFile,
          insuranceType,
          documentType: AirBundleDocumentParam.BOOKING,
          insuranceId: id,
          resourceId,
        });

        const passport = uploadDocument.mutateAsync({
          file: passportOrIdFile,
          insuranceType,
          documentType: AirBundleDocumentParam.PASSPORT,
          insuranceId: id,
          resourceId,
        });

        const cancelation = uploadDocument.mutateAsync({
          file: cancelationFile,
          insuranceType,
          documentType: AirBundleDocumentParam.CANCELATION,
          insuranceId: id,
          resourceId,
        });

        return Promise.all([booking, passport, cancelation]).then(
          ([booking, passport, cancelation]) => ({
            id,
            bookingReference: booking,
            passportOrId: passport,
            cancelation,
          }),
        );
      }
      if (!boardingPassFile || !passportOrIdFile) {
        return Promise.reject(new Error('Missing required files'));
      }

      const boarding = uploadDocument.mutateAsync({
        file: boardingPassFile,
        insuranceType,
        documentType: AirBundleDocumentParam.BOARDING,
        insuranceId: id,
        resourceId,
      });

      const passport = uploadDocument.mutateAsync({
        file: passportOrIdFile,
        insuranceType,
        documentType: AirBundleDocumentParam.PASSPORT,
        insuranceId: id,
        resourceId,
      });

      return Promise.all([boarding, passport]).then(([boarding, passport]) => ({
        id,
        boardingPass: boarding,
        passportOrId: passport,
      }));
    });

    Promise.all(promises)
      .then((results) => {
        results.forEach((result) => {
          dispatch({
            type: AirPayoutPaymentTypes.EDIT_PASSENGER,
            payload: result,
          });
        });

        setUploadDocumentsFinished(data);
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (uploadDocumentsFinished) {
      savePayout({ ...uploadDocumentsFinished });
    }
  }, [uploadDocumentsFinished]);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-bundle-payout-title"
        >
          {dataDetailsCollected
            ? t('common.documents')
            : t('instant_cash.payout.bank_details')}
        </Text>

        {dataDetailsCollected ? (
          journeyListData.map((airPayoutJourney) => (
            <DocumentsDetails
              key={airPayoutJourney.id}
              airPayoutJourney={airPayoutJourney}
              dispatch={dispatch}
              isMultiPax={isMultiPax}
              reducerDataById={reducerDataById}
              setFormsValidity={setFormsValidity}
            />
          ))
        ) : (
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            submitError={submitError}
            uploadInProgress={uploadInProgress}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
            insuranceType={insuranceType}
          />
        )}
      </Box>
      {isMultiPax && dataDetailsCollected ? (
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" display="inline-flex" mb={1} />}
          variant="secondary"
          mt={{ base: 4, md: 5 }}
        >
          {t('common.fill_out_application_separately')}
        </InfoBox>
      ) : null}
      <AirBundlePayoutActions
        form={form}
        uploadInProgress={uploadInProgress}
        dataDetailsCollected={dataDetailsCollected}
        setDataDetailsCollected={setDataDetailsCollected}
        onSubmit={onSubmit}
        submitError={submitError}
        isDisabled={isDisabled}
        insuranceType={insuranceType}
      />
    </>
  );
};

export default PayoutPaymentView;
