import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Autocomplete } from '@airhelp/react';
import { Flex, Text, VStack, Box, Link } from '@chakra-ui/react';

import config from 'config';
import { Card, ErrorBadge } from 'elements';
import ContactLink from 'elements/ContactLink/ContactLink';
import { getCountriesPayoutSelectOptions } from 'utils/airBundle/airBundle';

import BankDetailsForm from './BankDetailsForm';
import { InsuranceType } from '@airhelp/plus';

const PayoutForm = ({
  form,
  onSubmit,
  submitError,
  insuranceType,
  beneficiaryFullName,
}: {
  form: UseFormReturn;
  onSubmit: (data) => void;
  submitError: boolean;
  insuranceType: InsuranceType;
  uploadInProgress?: boolean;
  beneficiaryFullName?: string;
  journeyId: number;
}) => {
  const { t, i18n } = useTranslation();
  const payoutCountriesOptions = getCountriesPayoutSelectOptions(i18n.language);

  const { handleSubmit, watch, control, clearErrors } = form;

  const countryCode = watch('country')?.value || '';

  const handleCountrySelect = (
    item: { value: string; label: string },
    onChange: (item: { value: string; label: string }) => void,
  ) => {
    onChange(item);
    clearErrors('country');
  };

  const description =
    insuranceType === InsuranceType.AIR_LUGGAGE
      ? t('air_luggage.luggage_details.add_the_bank_details')
      : t('instant_cash.payout.add_the_bank_details');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection="column" alignItems="flex-start">
        <Card padding={{ base: 4, md: 5 }} overflow="visible">
          <Box>
            <Text fontSize="md" fontWeight="normal" mb={4}>
              {countryCode ? (
                description
              ) : (
                <Trans
                  i18nKey="instant_cash.payout.country_is_not_our_list"
                  values={{ contactEmail: config.contactEmail }}
                  components={{
                    a: <Link fontSize="md" fontWeight="normal" isExternal />,
                  }}
                />
              )}
            </Text>
            {!countryCode && (
              <Controller
                name="country"
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={null}
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid, error },
                }) => (
                  <Box
                    width="100%"
                    minHeight="108px"
                    pt={2}
                    pb={{ base: 4, md: 5 }}
                  >
                    <Autocomplete
                      name={name}
                      isInvalid={invalid}
                      errorMessage={error?.message || ''}
                      label={t('common.country_location_your_bank_account')}
                      value={value}
                      options={payoutCountriesOptions}
                      placeholder={t('common.select_country')}
                      isSearchable
                      handleChange={(value) =>
                        handleCountrySelect(value, onChange)
                      }
                      caption={t(
                        'instant_cash.payout.select_the_country_where_you_have_account',
                      )}
                      dataTestId="country"
                    />
                  </Box>
                )}
              />
            )}
          </Box>
          {countryCode ? (
            <VStack spacing={0} alignItems="flex-start" width="100%">
              <BankDetailsForm
                form={form}
                countryCode={countryCode}
                beneficiaryFullName={beneficiaryFullName}
              />
            </VStack>
          ) : null}
          {submitError ? (
            <ErrorBadge data-testid="backend-error">
              <ContactLink tKey="instant_cash.payout.request_payment_error" />
            </ErrorBadge>
          ) : null}
        </Card>
      </Flex>
    </form>
  );
};

export default PayoutForm;
