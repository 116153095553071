import {
  getUA,
  isConsole,
  isMobile,
  isTablet,
  mobileModel,
  osName,
} from 'react-device-detect';

import { BREAKPOINTS } from 'config/sassVariables';
import type UserProfile from 'models/userProfile/UserProfile';
import {
  getUserBusinessType,
  getUserHashedEmail,
  getUserSubscriptionName,
} from 'utils/tracking/props/ga4/user';

import type { UserTrackingData } from './types';

const pagePath = () => window.location.pathname;
const pageTitle = () => document.title;
const pageUrl = () => window.location.href;
const viewportWidth = () => window.innerWidth;
const viewportHeight = () => window.innerHeight;

const deviceModel = () => {
  return mobileModel !== 'none' ? mobileModel : osName || undefined;
};

const deviceType = () => {
  switch (true) {
    case isConsole:
      return 'Console';
    case isMobile:
      return 'Mobile';
    case isTablet:
      return 'Tablet';
    default:
      return 'Desktop';
  }
};

const webTheme = () => {
  switch (true) {
    case viewportWidth() <= BREAKPOINTS.PHONE:
      return 'Phone';
    case viewportWidth() < BREAKPOINTS.TABLET:
      return 'Tablet';
    default:
      return 'Desktop';
  }
};

const getPageViewedData = (appLocale: string, pageReferrer: string) => ({
  name: 'page_view', // required by current react-tracking implementation
  event: 'page_view', // will be required by GTM

  device_model: deviceModel(),
  device_type: deviceType(),
  page_language: appLocale,
  page_path: pagePath(),
  page_referrer: pageReferrer,
  page_title: pageTitle(),
  page_url: pageUrl(),
  user_agent: getUA,
  viewport_height: viewportHeight(),
  viewport_width: viewportWidth(),
  web_theme: webTheme(),
  page_currency: 'EUR',
});

const getUserData = (
  userProfile: UserProfile | undefined,
): UserTrackingData | null => {
  return {
    is_logged: Boolean(userProfile),
    user_id: userProfile ? userProfile.id : null,
    active_subscription: getUserSubscriptionName(userProfile),
    business_type: getUserBusinessType(userProfile),
    hashed_email_id: getUserHashedEmail(userProfile),
  };
};

export { getPageViewedData, getUserData };
