import React from 'react';
import {
  VStack,
  Box,
  Text,
  Image,
  HStack,
  Center,
  Heading,
} from '@chakra-ui/react';
import BenefitsList from './BenefitsList';
import GoToSiteButton from './GoToSiteButton';
import { PriceTag, Info } from '@airhelp/icons';
import { useTranslation, Trans } from 'react-i18next';
import { CopyToClipboardButton } from 'elements';
import { TranslationKey } from 'types/i18n';
import { InfoBox } from '@airhelp/react';

interface IComponent {
  heading: string;
  imageSrcMobile: string;
  perkLogo: string;
  discount?: number;
  subHeading: TranslationKey;
  descriptionPrimary: string;
  descriptionSecondary?: string;
  benefits: { content: string; key: string }[];
  code?: string;
  promoCodeText?: string;
  onCodeCopied?: () => void;
  goToSite: () => void;
  buttonLabel: string;
  infoBoxText?: string;
  imageTextColor?: string;
}

const PerkDrawerContent: React.FC<IComponent> = ({
  heading,
  perkLogo,
  discount,
  subHeading,
  descriptionPrimary,
  descriptionSecondary,
  benefits,
  code,
  onCodeCopied,
  promoCodeText,
  goToSite,
  buttonLabel,
  infoBoxText,
  imageTextColor = 'primary.900',
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        fontWeight="medium"
        position="absolute"
        fontSize={{ base: 'sm', md: 'md' }}
        top={{ base: 4, md: 8 }}
        left={{ base: 5, md: 8 }}
        color={{
          base: 'primary.900',
          md: imageTextColor ? imageTextColor : 'primary.900',
        }}
      >
        {heading}
      </Text>

      <VStack
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        minHeight="100%"
        spacing={{ base: 4, md: 8 }}
      >
        {/* Top section */}
        <Box>
          <VStack spacing={{ base: 4, md: 8 }}>
            <HStack
              justifyContent="space-between"
              alignItems="center"
              mb={{ base: 2, md: 0 }}
              width="100%"
            >
              <Image src={perkLogo} h={{ base: 4, md: 6 }} />
              {discount ? (
                <Center
                  borderRadius="sm"
                  px={2}
                  py={3}
                  h={4}
                  alignItems="center"
                  background="success.500"
                >
                  <PriceTag color="white" me={1} boxSize={4} />
                  <Text
                    color="white"
                    fontSize={{ base: 'xs', md: 'sm' }}
                    fontWeight="bold"
                  >
                    {t('dashboard.perks.percentage_off', {
                      percentage: discount,
                    })}
                  </Text>
                </Center>
              ) : null}
            </HStack>

            <VStack spacing={2}>
              <Heading
                variant={{ base: 'h5', md: 'h4' }}
                alignSelf="flex-start"
              >
                {discount ? (
                  <Trans
                    i18nKey={subHeading as unknown as TranslationKey[]}
                    values={{ percentage: discount }}
                    components={{
                      strong: (
                        <Text
                          display="inline"
                          fontSize="xl"
                          fontWeight="bold"
                          color="danger.500"
                        />
                      ),
                    }}
                  />
                ) : (
                  t(subHeading)
                )}
              </Heading>

              <Text fontWeight="medium" fontSize={{ base: 'sm', md: 'md' }}>
                {descriptionPrimary}
              </Text>
            </VStack>

            {descriptionSecondary ? (
              <Text fontSize={{ base: 'sm', md: 'md' }} color="greyscale.600">
                {descriptionSecondary}
              </Text>
            ) : null}

            <BenefitsList benefits={benefits} />
          </VStack>
        </Box>

        {infoBoxText ? (
          <InfoBox icon={<Info boxSize={5} />}>
            <Text color="greyscale.700" fontSize="md">
              {infoBoxText}
            </Text>
          </InfoBox>
        ) : null}

        {/* bottom section */}
        <Box
          p={4}
          background={{ base: 'greyscale.100', md: 'greyscale.300' }}
          borderRadius="3xl"
          width={{ base: 'calc(100% + 32px)', md: '100%' }}
          position={{ base: 'sticky', md: 'unset' }}
          bottom={0}
          zIndex={1}
          boxShadow={{
            base: '0px -4px 40px 0px rgba(13, 48, 115, 0.10)',
            md: 'none',
          }}
          ms={{ base: '-16px', md: 'unset' }}
          mb={{ base: '-32px', md: 'unset' }}
        >
          {code && onCodeCopied ? (
            <>
              <Text fontSize="sm" fontWeight="medium" pb={2}>
                {t('dashboard.perks.get_your_promocode')}
              </Text>
              <CopyToClipboardButton
                id="perk-code"
                inputValue={code}
                fullWidth
                valueAlign="left"
                onCopy={onCodeCopied}
              />

              <Text
                fontSize="md"
                color="greyscale.600"
                pt={2}
                pb={{ base: 4, md: 6 }}
              >
                {promoCodeText}
              </Text>
            </>
          ) : null}

          <GoToSiteButton onClick={goToSite} label={buttonLabel} />
        </Box>
      </VStack>
    </>
  );
};

export default PerkDrawerContent;
