import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';

import { AirPayoutPaymentPax } from '@airhelp/plus';

import UserCard from 'components/Journey/AirBundle/UserCard';
import {
  type Action,
  AirPayoutPaymentTypes,
} from 'reducers/airPayoutPaymentReducer/airPayoutPaymentReducer';

import DocumentsDetailsForm from './DocumentsDetailsForm';
import useGetAirPayoutStatusBadge from '../getAirPayoutStatusBadge';
import { useTrackEvent } from 'utils/tracking/hooks';
import { AirPayoutJourney } from 'models/journey/AirPayoutJourney';

interface IComponent {
  airPayoutJourney: AirPayoutJourney;
  dispatch: React.Dispatch<Action>;
  reducerDataById: Record<number, AirPayoutPaymentPax>;
  setFormsValidity: (id: number, valid: boolean) => void;
  isMultiPax: boolean;
}

const DocumentsDetails: React.FC<IComponent> = ({
  airPayoutJourney,
  dispatch,
  reducerDataById,
  isMultiPax,
  setFormsValidity,
}) => {
  const { trackCtaClick } = useTrackEvent();
  const form = useForm({ mode: 'onSubmit' });
  const { handleSubmit } = form;

  const { payoutAllowed, status, id } = airPayoutJourney;
  const paxFilled = !!reducerDataById[id];

  const { isOpen, onToggle, onClose } = useDisclosure({
    defaultIsOpen: !paxFilled && !isMultiPax,
  });
  const statusBox = useGetAirPayoutStatusBadge(status);

  const handleAddPax = (data) => {
    dispatch({
      type: AirPayoutPaymentTypes.EDIT_PASSENGER,
      payload: {
        id,
        ...data,
      },
    });
    onClose();
    setFormsValidity(id, true);
    trackCtaClick('documents added', 'ahp airPayout page');
  };

  if (!payoutAllowed) {
    return (
      <Box mt={4}>
        <UserCard
          airBundleJourney={airPayoutJourney}
          payoutAllowed={!!payoutAllowed}
          statusBox={statusBox}
        />
      </Box>
    );
  }

  return (
    <Flex
      flexDirection="column"
      border={isOpen ? '1px solid' : 'none'}
      borderColor="primary.500"
      borderRadius="xl"
      mt={4}
      data-testid="luggage-details-container"
    >
      <UserCard
        airBundleJourney={airPayoutJourney}
        payoutAllowed={payoutAllowed}
        isOpen={isOpen}
        handleEdit={onToggle}
        paxFilled={paxFilled}
        statusBox={statusBox}
        canEdit
      />
      <Collapse in={isOpen} animateOpacity key={airPayoutJourney.id}>
        <DocumentsDetailsForm
          form={form}
          airPayoutJourney={airPayoutJourney}
          reducerDataById={reducerDataById}
          handleAddPax={handleSubmit(handleAddPax)}
          setFormsValidity={setFormsValidity}
          canEdit
        />
      </Collapse>
    </Flex>
  );
};

export default DocumentsDetails;
