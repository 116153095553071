import React from 'react';

import { type IconType } from '@airhelp/icons';
import { HStack, type StackProps, Text, VStack } from '@chakra-ui/react';

type IComponent = {
  primaryLabel: string;
  secondaryLabel: string;
  Icon?: IconType;
} & StackProps;

const ClaimDetailsBadge: React.FC<IComponent> = ({
  primaryLabel,
  secondaryLabel,
  Icon,
  ...props
}) => {
  return (
    <VStack align="start" spacing={0} width={props.width} {...props}>
      <Text fontSize="sm">{primaryLabel}</Text>
      <HStack color="greyscale.600">
        {Icon ? <Icon boxSize={4} /> : null}
        <Text fontSize="sm" color="greyscale.600" noOfLines={props.noOfLines}>
          {secondaryLabel}
        </Text>
      </HStack>
    </VStack>
  );
};

export default ClaimDetailsBadge;
