import React from 'react';
import {
  Flex,
  Button,
  VStack,
  useDisclosure,
  useToast,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import { Edit, Info } from '@airhelp/icons';
import LabelValue from '../../common/LabelValue/LabelValue';
import CreditCard from '../CreditCardComponent';
import { BillingManagement } from '@airhelp/plus';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Section from 'components/MyAccountView/BenefitsView/Section';
import CreditCardDrawer from '../UpdateCreditCard/CreditCardDrawer';
import PaymentMethodInfo from '../PaymentMethodInfo';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { InfoBox } from '@airhelp/react';
import useUpdateCreditCard from 'hooks/api/billingManagement/useUpdateCreditCard';

interface IComponent {
  billingManagement: BillingManagement;
}

const PaymentCardView: React.FC<IComponent> = ({ billingManagement }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { userProfile } = useMyProfile();

  const { subscription, payment, billingAddress } = billingManagement;
  const { nextChargeAmount, nextChargeDate, nextChargeCurrencySymbol } =
    subscription;

  const isCardPayment = payment?.type === 'card';
  const isNotCardPayment = payment && !isCardPayment;

  const showOnGracePeriodInfo = Boolean(
    userProfile?.policy?.membership?.onGracePeriod,
  );

  const handleUpdatePayment = () => {
    trackCtaClick('update payment method', 'ahp payment management');
    handleUpdatePaymentMethod(
      {},
      {
        onSuccess: () => {
          onOpen();
        },
        onError: () => {
          toast({
            title: <span>{t('errors.something_went_wrong')}</span>,
            status: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
          });
        },
      },
    );
  };

  const {
    mutate: handleUpdatePaymentMethod,
    data: updateCreditCardData,
    isPending: isLoading,
    isError,
  } = useUpdateCreditCard();

  return (
    <>
      <Section header={t('payment_management.payment_methods')}>
        <VStack
          w={{ base: '100%', md: '67%' }}
          mt={{ base: 4, md: 0 }}
          borderWidth={{ base: '0', md: '1px' }}
          borderColor="greyscale.400"
          borderRadius="lg"
          p={{ base: 0, md: 4 }}
          alignItems="flex-start"
          spacing={{ base: 4, md: 5 }}
        >
          <Flex
            backgroundColor={{ base: 'greyscale.100', md: 'greyscale.300' }}
            width="100%"
            padding={{ base: 0, md: 4 }}
            borderRadius={{ base: 'none', md: 'xl' }}
            justifyContent="center"
            data-testid="payment-method-card-content"
          >
            {isCardPayment ? <CreditCard payment={payment} /> : null}
            {isNotCardPayment ? <PaymentMethodInfo payment={payment} /> : null}
          </Flex>
          <VStack spacing={{ base: 2, md: 3 }} width="100%">
            <LabelValue
              label={t('payment_management.membership_price')}
              value={`${nextChargeCurrencySymbol}${nextChargeAmount}  / ${t('common.year')}`}
            />
            <LabelValue
              label={t('payment_management.next_payment')}
              value={format(nextChargeDate, 'dd.MM.yyyy')}
            />
          </VStack>
          <Button
            size="lg"
            leftIcon={<Edit boxSize={{ base: 4, xlg: 5 }} />}
            variant="link"
            color="primary.500"
            fontWeight="normal"
            fontSize={{ base: 'sm', md: 'md' }}
            justifyContent="flex-start"
            width="100%"
            onClick={handleUpdatePayment}
            disabled={isError || isLoading}
            isLoading={isLoading}
            data-testid="btn-update-payment-method"
          >
            {t('payment_management.update_payment_method')}
          </Button>
          {showOnGracePeriodInfo ? (
            <InfoBox
              icon={<Info boxSize={5} data-testid="grace-period-info" />}
            >
              <Text color="greyscale.700" fontSize="md">
                {`${t('payment_management.update_your_payment_method')} ${t('payment_management.charge_you_once_it_saved')}`}
              </Text>
            </InfoBox>
          ) : null}
        </VStack>
      </Section>
      {updateCreditCardData ? (
        <CreditCardDrawer
          isOpen={isOpen}
          onClose={onClose}
          creditCardData={updateCreditCardData}
          billingAddress={billingAddress}
          isUpdate
        />
      ) : null}
    </>
  );
};

export default PaymentCardView;
