import React, { useState, useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import track from 'react-tracking';

import { startOfDay } from 'date-fns';

import ReturnFlightView from 'components/Flights/New/ReturnFlightView';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext } from 'contexts';
import { PageLoader } from 'elements';
import { type ERROR_CODES } from 'types/api';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchAirBundleSubscription from 'hooks/api/userProfiles/useFetchAirBundleSubscription';
import useProtectedPage from 'hooks/routing/useProtectedPage';

const ReturnFlightPage = () => {
  usePageTracking();
  useProtectedPage();

  const { trackCtaClick, trackAddFlightFunnel } = useTrackEvent();
  const navigate = useNavigate();

  const {
    isLoading: isLoadingAirBundleSubscription,
    data: airBundleSubscription,
  } = useFetchAirBundleSubscription({
    gcTime: 0,
  });
  const { saveJourney, journey, itineraryIndex, fromRoute } =
    useContext(EditedJourneyContext);
  const [apiErrorCode, setApiErrorCode] = useState<ERROR_CODES | null>(null);
  const [isSavingJourney, setIsSavingJourney] = useState(false);

  if (isLoadingAirBundleSubscription) {
    return <PageLoader />;
  }

  const airLuggageAccess = Boolean(airBundleSubscription?.airLuggageAccess);
  const firstFlightLocalDepartureDate =
    journey.itineraries[0].flights[0].localDepartureDate;
  const firstFlightInPast =
    !!firstFlightLocalDepartureDate &&
    firstFlightLocalDepartureDate < startOfDay(new Date());

  const nextStep = async (hasReturn: boolean) => {
    if (hasReturn) {
      navigate({
        pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.ROUTE_DESTINATION_PAGE),
        search: `?itineraryIndex=${itineraryIndex + 1}&src=${fromRoute}`,
      });
    } else if (airLuggageAccess && !firstFlightInPast) {
      navigate({
        pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.AIR_LUGGAGE_PAGE),
        search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
      });
    } else {
      setIsSavingJourney(true);
      const result = await saveJourney();

      if (!result.errors && result.journey) {
        navigate(
          generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, {
            id: result.journey.id,
          }),
          {
            state: { showAirPayoutSubscriptionModal: true },
          },
        );
        trackAddFlightFunnel('add this flight');
      } else if (result.errors && result.errorCode) {
        setApiErrorCode(result.errorCode);
      }
      setIsSavingJourney(false);
    }
  };

  const previousStep = () => {
    navigate(-1);
    trackCtaClick('back', 'return flights');
  };

  return (
    <ReturnFlightView
      airLuggageAccess={airLuggageAccess}
      onNextStep={nextStep}
      onPreviousStep={previousStep}
      isSavingJourney={isSavingJourney}
      apiErrorCode={apiErrorCode}
      firstFlightInPast={firstFlightInPast}
    />
  );
};

export default track({ page_type: 'Flights.New.ReturnFlights' })(
  ReturnFlightPage,
);
