import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';
import { deleteJourney, DeleteJourneySchemaParams } from '@airhelp/plus';

const useDeleteJourney = () => {
  const queryClient = useQueryClient();

  const useDeleteJourneyMutation = useMutation({
    mutationFn: (params: DeleteJourneySchemaParams) =>
      deleteJourney({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['journeys'],
      });

      queryClient.removeQueries({
        queryKey: ['journey', { data }],
      });
    },
  });

  return useDeleteJourneyMutation;
};

export default useDeleteJourney;
