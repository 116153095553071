import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format as formatDate } from 'date-fns';

import {
  dateFnLocaleHelper,
  useLocale,
} from 'contexts/LocaleContext/LocaleContextProvider';
import { ErrorBadge } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type MembershipInfo } from 'models/MembershipInfo';
import useCancelMembership from 'hooks/api/useCancelMembership/useCancelMembership';
import { sentryUtils } from 'utils';

interface Props {
  render?: (openModalHandler: () => void) => void;
  membershipInfo?: MembershipInfo | null;
  endDate?: string;
}

const CancelConfirmationModal: React.FC<Props> = ({
  render,
  membershipInfo,
  endDate,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const queryClient = useQueryClient();
  const cancelMembershipApi = useCancelMembership();
  const { trackEvent } = useTracking();

  const { refetch: refetchUserProfile } = useFetchMyProfile();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCanceledError, setIsCanceledError] = useState<boolean | undefined>(
    undefined,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const validUntil = membershipInfo?.validUntil || endDate;
  const dateLocale = dateFnLocaleHelper(locale);

  const expiredDate = validUntil
    ? formatDate(new Date(validUntil), 'dd LLLL, yyyy', { locale: dateLocale })
    : null;

  const processCancelMembership = async () => {
    setIsSubmitting(true);
    try {
      setIsSubmitting(true);
      await cancelMembershipApi.mutateAsync({});
      setIsSubmitted(true);
      setIsCanceledError(false);
      await queryClient.invalidateQueries({ queryKey: ['membershipInfo'] });

      await refetchUserProfile();
    } catch (error) {
      setIsCanceledError(true);
      sentryUtils.captureException(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModalHandler = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'opened',
      eventLabel: 'ahplus_cancel_membership_modal_opened',
    });
    setIsCanceledError(false);
    setIsSubmitted(false);
    onOpen();
  };

  const closeModalHandler = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'closed',
      eventLabel: 'ahplus_cancel_membership_modal_closed',
    });
    onClose();
  };

  const cancelMembership = () => {
    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_cancel_membership_modal_canceled',
    });
    processCancelMembership();
  };

  return (
    <>
      {render ? render(openModalHandler) : null}
      <Modal
        isOpen={isOpen}
        onClose={closeModalHandler}
        size="xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            borderRadius="sm"
            boxSize={2}
            top={{ base: 4, md: 6 }}
            right={{ base: 4, md: 6 }}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2.5 },
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            data-testid="modal-close-button"
          />
          <ModalBody p={{ base: 4, md: 6 }} sx={{ overflow: 'auto' }}>
            <VStack spacing={6} alignItems={{ base: 'auto', md: 'flex-end' }}>
              <Heading variant="h5" me={10}>
                {t('my_account.membership.do_you_want_to_cancel_your_ahplus')}
              </Heading>
              <Box fontSize="md">
                <Trans
                  i18nKey="my_account.membership.the_cancellation_will_come"
                  values={{ date: expiredDate }}
                  components={{
                    strong: (
                      <Text as="span" fontSize="md" fontWeight="medium" />
                    ),
                  }}
                />
              </Box>
              {isCanceledError ? (
                <Box mt={0}>
                  <ErrorBadge />
                </Box>
              ) : null}
              <Stack
                direction={{ base: 'column', md: 'row-reverse' }}
                justifyContent="flex-start"
                spacing={2}
              >
                <Button
                  variant="danger"
                  onClick={cancelMembership}
                  size={{ base: 's', md: 'm' }}
                  textTransform="capitalize"
                  width={{ base: '100%', md: 'auto' }}
                  isDisabled={isSubmitted}
                  isLoading={isSubmitting}
                  data-testid="btn-cancel"
                >
                  {t('my_account.membership.yes_cancel')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={closeModalHandler}
                  size={{ base: 's', md: 'm' }}
                  textTransform="capitalize"
                  width={{ base: '100%', md: 'auto' }}
                  data-testid="btn-go-back"
                >
                  {t('my_account.membership.go_back')}
                </Button>
              </Stack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelConfirmationModal;
