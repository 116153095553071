import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Airplane,
  AirplaneLanding,
  AirplaneTakeOff,
  Calendar,
} from '@airhelp/icons';
import { Divider, Flex, HStack } from '@chakra-ui/react';
import { format as formatDate } from 'date-fns-tz';

import { dateFnLocaleHelper } from 'contexts/LocaleContext/LocaleContextProvider';
import { type ClaimEnquiry } from 'models/Claim';

import ClaimDetailsBadge from './ClaimDetailsBadge';
import AirportBadge from '../AirportBadge';
import ClaimActions from '../ClaimActions';

interface IComponent {
  claimEnquiry: ClaimEnquiry;
  sourceParam?: string;
  onDeleteClaimClick?: () => void;
  onCompleteClaimClick?: () => void;
}

const ClaimListItem: React.FC<IComponent> = ({
  claimEnquiry,
  onDeleteClaimClick,
  onCompleteClaimClick,
  sourceParam,
}) => {
  const { t, i18n } = useTranslation();

  const {
    localDepartureDate,
    departureCity,
    departureAirportCode,
    arrivalCity,
    arrivalAirportCode,
    airline,
  } = claimEnquiry.disruptedFlight;
  const { claimId, createdAt } = claimEnquiry;

  const dateLocale = dateFnLocaleHelper(i18n.language);
  const disruptedDepartureDate = formatDate(
    localDepartureDate,
    'dd LLLL yyyy',
    {
      locale: dateLocale,
    },
  );
  const claimCreatedDate = formatDate(new Date(createdAt), 'dd LLLL yyyy', {
    locale: dateLocale,
  });

  return (
    <Flex
      width="100%"
      gap={4}
      p={4}
      backgroundColor="greyscale.100"
      borderRadius="lg"
      flexDirection={{ base: 'column', xlg: 'row' }}
      data-testid={`claim-list-item-${claimId ? claimId : claimEnquiry.id}`}
    >
      <HStack
        spacing={4}
        width={{ base: '100%', xlg: '38%' }}
        flexShrink="0"
        align="flex-start"
      >
        <AirportBadge
          city={departureCity}
          iata={departureAirportCode}
          Icon={AirplaneTakeOff}
          data-testid="departure-airport"
        />
        <AirportBadge
          city={arrivalCity}
          iata={arrivalAirportCode}
          Icon={AirplaneLanding}
          data-testid="arrival-airport"
        />
      </HStack>
      <Divider
        borderColor="greyscale.400"
        borderWidth="1px"
        display={{ base: 'block', xlg: 'none' }}
      />
      <HStack width={{ base: '100%', xlg: '62%' }}>
        <HStack
          spacing={4}
          width={claimId ? '90%' : '55%'}
          align="flex-start"
          flexShrink="0"
        >
          <ClaimDetailsBadge
            primaryLabel={t('common.airline')}
            secondaryLabel={airline}
            Icon={Airplane}
            width={claimId ? '30%' : '50%'}
            noOfLines={1}
            data-testid="airline-wrapper"
          />
          <ClaimDetailsBadge
            primaryLabel={t('common.flight_date')}
            secondaryLabel={disruptedDepartureDate}
            Icon={Calendar}
            width={claimId ? '30%' : '50%'}
            data-testid="flight-date-wrapper"
          />
          {claimId ? (
            <ClaimDetailsBadge
              primaryLabel={t('claim_card.claim_id', { claim_id: claimId })}
              secondaryLabel={t('claims.created', { date: claimCreatedDate })}
              width="40%"
              data-testid="claim-id-wrapper"
            />
          ) : null}
        </HStack>
        <HStack
          spacing={4}
          width={claimId ? 'auto' : '45%'}
          maxWidth="100%"
          justify="flex-end"
          flexGrow="1"
        >
          <ClaimActions
            claimEnquiry={claimEnquiry}
            onDeleteClaimClick={onDeleteClaimClick}
            onCompleteClaimClick={onCompleteClaimClick}
            sourceParam={sourceParam}
          />
        </HStack>
      </HStack>
    </Flex>
  );
};

export default ClaimListItem;
