import { Flex, VStack, Text } from '@chakra-ui/react';
import { formatValueToCurrency } from 'stores/preferences/currencies';
import { usePreferencesStore } from 'stores/preferences';
import { ID, MoneyPaper } from '@airhelp/icons';
import { ShortInfo } from './parts/ShortInfo';
import { Airport } from './parts/Airport';
import { Separator } from './parts/Separator';

interface ClaimOverviewProps {
  departureAirportCountryCode: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: string;
  arrivalAirportCode: string;
  claimId: number | string;
  compensation: string;
}

export function ClaimOverview({
  departureAirportCountryCode,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportCode,
  claimId,
  compensation,
}: ClaimOverviewProps) {
  return (
    <Flex
      p={6}
      rounded="3xl"
      justifyContent="space-between"
      boxShadow="lg"
      backgroundColor="primary.600"
      flexDirection={{
        base: 'column',
        md: 'row',
      }}
    >
      <FlightSummary
        departureAirportCode={departureAirportCode}
        departureAirportCountryCode={departureAirportCountryCode}
        arrivalAirportCode={arrivalAirportCode}
        arrivalAirportCountryCode={arrivalAirportCountryCode}
      />
      <CompensationInfo claimId={claimId} compensation={compensation} />
    </Flex>
  );
}

interface FlightSummaryProps {
  departureAirportCountryCode: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: string;
  arrivalAirportCode: string;
}

function FlightSummary({
  departureAirportCountryCode,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportCode,
}: FlightSummaryProps) {
  return (
    <VStack
      spacing={2}
      maxW={{
        base: '100%',
        md: '50%',
      }}
      flex={1}
    >
      <Flex justifyContent="space-between" w="100%">
        <Airport
          airport={departureAirportCode}
          airportCountryCode={departureAirportCountryCode}
        />
        <Airport
          direction="row-reverse"
          airport={arrivalAirportCode}
          airportCountryCode={arrivalAirportCountryCode}
        />
      </Flex>
      <Flex justifyContent="space-between" w="100%" gap={6}>
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {departureAirportCode}
        </Text>
        <Separator />
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {arrivalAirportCode}
        </Text>
      </Flex>
    </VStack>
  );
}

interface CompensationInfoProps {
  compensation: string;
  claimId: number | string;
  iconPlacement?: 'left' | 'right';
}

function CompensationInfo({ compensation, claimId }: CompensationInfoProps) {
  const currency = usePreferencesStore((state) => state.currency);

  const localCompensation = formatValueToCurrency(compensation, currency);

  return (
    <Flex
      gap={2}
      justifyContent={{
        base: 'space-between',
        md: 'flex-end',
      }}
    >
      <ShortInfo icon={ID} label="Claim ID" value={claimId} />
      <ShortInfo
        icon={MoneyPaper}
        label="Compensation"
        value={localCompensation}
      />
    </Flex>
  );
}
