import { Loader } from 'elements';
import { Suspense } from 'react';

import { useParams } from 'react-router-dom';
import { useClaimSuspense } from '@airhelp/webapp/react';
import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { Header } from './Header/Header';

interface LayoutProps {
  faqEnabled?: boolean;
  children: React.ReactNode;
}

export function Layout({ children, faqEnabled = true }: LayoutProps) {
  const { id } = useParams();
  const { data: claim } = useClaimSuspense({
    params: { id: Number(id) },
  });

  const templateAreas = useBreakpointValue({
    base: `"header"
      "main-content"`,
    xl: `"header header"
      "main-content faq"`,
  });

  if (!claim) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Grid
        columnGap={8}
        templateAreas={templateAreas}
        autoRows="min-content"
        templateColumns={{
          base: '1fr',
          xl: '785px 377px',
        }}
      >
        <GridItem area="header">
          <Header title="Your Claim" />
        </GridItem>
        <GridItem area="main-content">{children}</GridItem>
        {faqEnabled ? (
          <GridItem
            area="faq"
            rounded="xl"
            backgroundColor="greyscale.100"
            display={{
              base: 'none',
              xl: 'grid',
            }}
          >
            FAQ section
          </GridItem>
        ) : null}
      </Grid>
    </Suspense>
  );
}
