import React from 'react';
import {
  Button,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Edit, Info } from '@airhelp/icons';
import LabelValue from '../common/LabelValue/LabelValue';
import { BillingManagementAddress } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';
import Section from 'components/MyAccountView/BenefitsView/Section';
import UpdateBillingInformation from './UpdateBillingInformation';
import { useTrackEvent } from 'utils/tracking/hooks';
import CreditCardDrawer from '../PaymentMethodsView/UpdateCreditCard/CreditCardDrawer';
import useUpdateCreditCard from 'hooks/api/billingManagement/useUpdateCreditCard';

interface IComponent {
  billingAddress: BillingManagementAddress;
  legacy: boolean;
}

const BillingInformationView: React.FC<IComponent> = ({
  billingAddress,
  legacy,
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { onClose, isOpen, onOpen } = useDisclosure();

  const {
    name,
    city,
    countryCode,
    postalCode,
    addressLine1,
    addressLine2,
    state,
  } = billingAddress;

  const handleUpdateAddress = () => {
    trackCtaClick('update address', 'ahp payment management');
    onOpen();
  };

  const handleUpdatePayment = () => {
    trackCtaClick('update payment method - truevo', 'ahp payment management');
    handleUpdatePaymentMethod(
      {},
      {
        onSuccess: () => {
          onOpen();
        },
        onError: () => {
          toast({
            title: <span>{t('errors.something_went_wrong')}</span>,
            status: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
          });
        },
      },
    );
  };

  const {
    mutate: handleUpdatePaymentMethod,
    data: updateCreditCardData,
    isPending: isLoading,
    isError,
  } = useUpdateCreditCard();

  return (
    <>
      <Section header={t('payment_management.billing_address')}>
        <VStack
          w={{ base: '100%', md: '67%' }}
          mt={{ base: 4, md: 0 }}
          borderWidth={{ base: '0', md: '1px' }}
          borderColor="greyscale.400"
          borderRadius="lg"
          p={{ base: 0, md: 4 }}
          alignItems="flex-start"
          spacing={{ base: 4, md: 5 }}
          data-testid="update-billing-address-content"
        >
          <VStack
            spacing={{ base: 2, md: 3 }}
            width="100%"
            alignItems="flex-start"
          >
            <LabelValue label={t('common.name')} value={name} />
            <LabelValue
              label={t('common.address')}
              value={`${addressLine1} ${addressLine2 ? addressLine2 : ''}`}
            />
            <LabelValue
              label={t('common.city')}
              value={`${postalCode ? postalCode : ''} ${city} ${state ? state : ''} ${countryCode}`}
            />
          </VStack>
          <Button
            size="lg"
            leftIcon={<Edit boxSize={{ base: 4, xlg: 5 }} />}
            variant="link"
            color="primary.500"
            fontWeight="normal"
            fontSize={{ base: 'sm', md: 'md' }}
            justifyContent="flex-start"
            onClick={legacy ? handleUpdatePayment : handleUpdateAddress}
            disabled={isError || isLoading}
            isLoading={isLoading}
            data-testid={
              legacy
                ? 'btn-update-payment-method'
                : 'btn-update-billing-address'
            }
          >
            {t('payment_management.update_billing_address')}
          </Button>
        </VStack>
      </Section>
      {!legacy ? (
        <UpdateBillingInformation
          isOpen={isOpen}
          onClose={onClose}
          billingAddress={billingAddress}
        />
      ) : (
        updateCreditCardData && (
          <CreditCardDrawer
            isOpen={isOpen}
            onClose={onClose}
            creditCardData={updateCreditCardData}
            billingAddress={billingAddress}
            isUpdate
          />
        )
      )}
    </>
  );
};

export default BillingInformationView;
