import {
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';

export function Status() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const placement: 'bottom' | 'right' =
    useBreakpointValue({ base: 'bottom', md: 'right' }) || 'bottom';
  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 6,
      }}
    >
      <Button variant="secondary" onClick={onOpen}>
        View status details
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement={placement}>
        <DrawerOverlay />
        <DrawerContent
          m={{
            base: 0,
            md: 4,
          }}
          rounded="xl"
          p={{
            base: 4,
            md: 8,
          }}
        >
          <Button onClick={onClose}>Close</Button>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
