import React from 'react';

import { Check, type IconType, Info } from '@airhelp/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';

interface UpsellBenefit {
  text: string;
  color?: string;
  weight?: string;
  tip?: string;
}

interface UpsellCardProps {
  title: string;
  Icon: IconType;
  iconSize?: number;
  cardColor?: string;
  benefitLabel: string;
  benefits: UpsellBenefit[];
  tag: string;
  tagBackgroundColor?: string;
  action?: () => void;
  actionButtonLabel?: string;
  actionDescription?: string;
  dataTestId?: string;
}

const TripCard = ({
  title,
  Icon,
  iconSize,
  cardColor,
  benefitLabel,
  benefits,
  tag,
  tagBackgroundColor,
  action,
  actionButtonLabel,
  actionDescription,
  dataTestId,
}: UpsellCardProps) => {
  return (
    <Flex
      width="100%"
      maxW={{ base: '100%', md: '335px' }}
      minHeight="515px"
      flexDirection="column"
      _first={{ mb: { base: 4, xlg: 0 } }}
      data-testid={dataTestId}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={cardColor || 'primary.900'}
        borderTopRadius="2xl"
        minHeight="180px"
        height="180px"
      >
        <Icon w="auto" h={iconSize ? iconSize : 8} color="greyscale.100" />
        <Heading variant="h2" color="greyscale.100" textAlign="center" mt={5}>
          {title}
        </Heading>
      </Flex>
      <Flex
        flexDirection="column"
        p={6}
        pb={8}
        height="100%"
        borderBottomRadius="2xl"
        borderX="1px solid"
        borderBottom="1px solid"
        borderColor="greyscale.400"
        backgroundColor={action ? 'greyscale.100' : 'greyscale.200'}
        justifyContent="space-between"
      >
        <Box>
          <Flex flexDirection="row" width="100%" justifyContent="space-between">
            <Text fontSize="2xl" fontWeight="bold">
              {benefitLabel}
            </Text>
            <Tag
              size="lg"
              backgroundColor={tagBackgroundColor || 'greyscale.300'}
            >
              <TagLabel
                fontSize="sm"
                fontWeight="bold"
                textTransform="uppercase"
                color={cardColor || 'greyscale.500'}
              >
                {tag}
              </TagLabel>
            </Tag>
          </Flex>
          <List spacing={4} mt={4}>
            {benefits.map((item) => (
              <ListItem key={item.text} display="flex" alignItems="center">
                <Check w={4} h="auto" mt={1} me={3} color="success.600" />
                <Text
                  fontSize="md"
                  color={item.color || 'primary.900'}
                  fontWeight={item.weight || 'normal'}
                >
                  {item.text}
                  {Boolean(item.tip) && (
                    <Tooltip
                      label={item.tip}
                      hasArrow
                      arrowPadding={6}
                      bgColor="greyscale.800"
                      borderRadius="md"
                      p={4}
                    >
                      <Info
                        color="primary.900"
                        boxSize={4}
                        h="auto"
                        ms={2}
                        verticalAlign="middle"
                        cursor="pointer"
                      />
                    </Tooltip>
                  )}
                </Text>
              </ListItem>
            ))}
          </List>
        </Box>
        {action ? (
          <Box mb={-5} mt={{ base: 12, xlg: 5 }}>
            <Button
              size="m"
              textAlign="center"
              onClick={action}
              width="100%"
              data-testid="trip-card-action-button"
            >
              {actionButtonLabel}
            </Button>
            <Text fontSize="xs" textAlign="center" mt={4}>
              {actionDescription}
            </Text>
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default TripCard;
