import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { Button } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { TranslationKey } from 'types/i18n';
import { AirLuggageStatus } from '@airhelp/plus';
import { useTrackEvent } from 'utils/tracking/hooks';
import { AirLuggageJourney } from 'models/journey/AirLuggageJourney';

const AirLuggageAction = ({
  airLuggageJourney,
}: {
  airLuggageJourney: AirLuggageJourney;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();
  const { status } = airLuggageJourney;

  const getPaidHandler = (update: boolean) => {
    trackCtaClick(
      update ? 'update bank details' : 'apply for payout',
      'ahp airLuggage page',
    );

    const page = update
      ? PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_UPDATE_PAGE
      : PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_PAGE;
    const search =
      update && airLuggageJourney.transactionReference
        ? `?tr=${encodeURIComponent(airLuggageJourney.transactionReference)}`
        : '';

    navigate(
      generatePath(page, { id: airLuggageJourney.journeyId.toString() }) +
        search,
    );
  };

  const renderButton = (tKey: TranslationKey, update = false) => (
    <Button
      onClick={() => getPaidHandler(update)}
      size="xs"
      backgroundColor="danger.500"
      color="greyscale.100"
      maxHeight="32px"
      _hover={{ backgroundColor: 'danger.400' }}
      data-testid={
        update
          ? 'air-luggage-update-bank-details-button'
          : 'air-luggage-get-paid-button'
      }
    >
      {t(tKey)}
    </Button>
  );

  switch (status) {
    case AirLuggageStatus.ELIGIBLE:
      return renderButton('air_luggage.apply_for_luggage');
    case AirLuggageStatus.PAYOUT_FAILED:
    case AirLuggageStatus.PAYOUT_RETURNED:
      return renderButton('common.update_bank_details', true);
    default:
      return t('common.no_action');
  }
};

export default AirLuggageAction;
