import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, Checkbox, Text, VStack } from '@chakra-ui/react';

import { marketingConsentLabel, privacyConsentLabel } from './common';
import { SignupSubmitData } from 'models/userProfile/Signup';

type FormType = UseFormReturn<SignupSubmitData, object>;

const Consents: React.FC<{
  requireTerms?: boolean;
  requireMarketing?: boolean;
  form: FormType;
}> = ({ form, requireTerms, requireMarketing }) => {
  const { t } = useTranslation();

  const { control, formState, watch, setValue } = form;
  const { errors } = formState;
  const formFieldsWatch = watch();
  const { marketingConsent, privacyConsent } = formFieldsWatch;

  const handleSelectAll = () => {
    if (privacyConsent && marketingConsent) {
      setValue('privacyConsent', false, { shouldValidate: true });
      setValue('marketingConsent', false);
    } else {
      setValue('privacyConsent', true, { shouldValidate: true });
      setValue('marketingConsent', true);
    }
  };

  const consents = [privacyConsent, marketingConsent];
  const allChecked = consents.every(Boolean);

  return (
    <VStack alignItems="center" spacing={3} width="100%">
      {requireTerms && requireMarketing ? (
        <FormControl w="100%">
          <Controller
            render={({ field: { name } }) => (
              <Checkbox
                size="lg"
                name={name}
                isChecked={allChecked}
                onChange={handleSelectAll}
                alignItems="flex-start"
                data-testid="select-all-consent"
              >
                <Text as="span" fontSize="sm" color="greyscale.600">
                  {t('common.select_all')}
                </Text>
              </Checkbox>
            )}
            name="selectAllConsent"
            control={control}
            defaultValue={false}
          />
        </FormControl>
      ) : null}
      {requireTerms ? (
        <FormControl w="100%" isInvalid={Boolean(errors.privacyConsent)}>
          <Controller
            render={({
              field: { onChange, name },
              fieldState: { invalid },
            }) => (
              <Checkbox
                name={name}
                onChange={onChange}
                size="lg"
                isChecked={privacyConsent}
                isInvalid={invalid}
                alignItems="flex-start"
                data-testid="agree-tc-and-privacy-consent"
              >
                {privacyConsentLabel()}
              </Checkbox>
            )}
            name="privacyConsent"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('errors.required') },
            }}
          />
        </FormControl>
      ) : null}
      {requireMarketing ? (
        <FormControl w="100%">
          <Controller
            render={({ field: { name, onChange } }) => (
              <Checkbox
                size="lg"
                name={name}
                onChange={onChange}
                isChecked={marketingConsent}
                alignItems="flex-start"
                data-testid="marketing-consent"
              >
                {marketingConsentLabel()}
              </Checkbox>
            )}
            name="marketingConsent"
            control={control}
            defaultValue={false}
          />
        </FormControl>
      ) : null}
    </VStack>
  );
};

export default Consents;
