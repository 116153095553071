import React from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import track from 'react-tracking';

import CompleteView from 'components/Trips/Complete/CompleteView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { ProductName } from '@airhelp/plus';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

const CompletePage: React.FC = () => {
  usePageTracking();
  const searchParams = new URLSearchParams(location.search);

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data: offersData,
  } = useComputedOffers({
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  if (isLoadingOffers) {
    return <PageLoader />;
  }

  if (isErrorOffers) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  if (!offersData) {
    return null;
  }

  const offers = offersData.offers;
  const product = offers[ProductName.UPGRADE_TO_TRIP_COMPLETE];
  return <CompleteView product={product} />;
};

export default track({ page_type: 'Trips.Upgrade.Complete' })(CompletePage);
