import { BANK_INPUTS } from '@airhelp/plus';

export const defaultFieldsConfig = [BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT];

type Rules = Record<string, Rule>;

interface Rule {
  minLength?: number;
  maxLength?: number;
  optional?: boolean;
}

export interface PayoutCountry {
  fields?: BANK_INPUTS[];
  rules?: Rules;
  ibanCountryCode?: string; // used for countries with different iban country code like New Caledonia
}

const paymentsFieldsConfig: Record<string, PayoutCountry> = {
  AD: {}, // Andorra
  AE: {}, // United Arab Emirates
  AM: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { minLength: 11, maxLength: 16 },
    },
  }, // Armenia
  AT: {}, // Austria
  AU: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BSB_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 9 },
    },
  }, // Australia
  AR: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.TAX,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 22 },
      tax: { minLength: 11, maxLength: 12 },
    },
  }, // Argentina
  AL: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 28, maxLength: 28 },
    },
  }, // Albania
  AZ: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.BIC_CODE,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      iban: { minLength: 28, maxLength: 28 },
    },
  }, //Azerbaijan
  BA: {}, // Bosnia and Herzegovina
  BE: {}, // Belgium
  BG: {}, // Bulgaria
  BH: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: { iban: { minLength: 22, maxLength: 22 } },
  }, // Bahrain
  BI: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: { accountNumber: { maxLength: 128 } },
  }, // Burundi
  BL: {}, // Saint Barthelemy
  BR: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BRANCH_AGENCY_CODE,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { maxLength: 18 },
      tax: { minLength: 11, maxLength: 14 },
    },
  }, // Brazil
  CA: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.ROUTING_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: { accountNumber: { maxLength: 12 } },
  }, // Canada
  CI: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.SWIFT,
    ],
    rules: { accountNumber: { maxLength: 24 } },
  }, // Ivory Coast
  CL: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { maxLength: 18 },
      tax: { minLength: 8, maxLength: 9 },
    },
  }, // Chile
  CN: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BANK_CITY,
      BANK_INPUTS.PHONE_NUMBER,
      BANK_INPUTS.BRANCH_NAME,
      BANK_INPUTS.CHINESE_ID,
      BANK_INPUTS.PROVINCE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
      tax: { minLength: 8, maxLength: 9 },
    },
  }, // China
  CY: {}, // Cyprus
  CZ: {}, // Czechia
  CH: {
    fields: [BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 21, maxLength: 21 },
    },
  }, // Switzerland
  CO: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
      tax: { minLength: 6, maxLength: 10 },
    },
  }, // Colombia
  CM: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 23 },
    },
  }, // Cameroon
  CR: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { minLength: 17, maxLength: 17 },
      tax: { minLength: 7, maxLength: 10 },
    },
  }, // Costa Rica
  DE: {}, // Germany
  DO: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
      tax: { minLength: 9, maxLength: 11 },
    },
  }, // Dominican Republic
  DK: {}, // Denmark
  DZ: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: { accountNumber: { maxLength: 20 } },
  }, // Algeria
  EE: {}, // Estonia
  EG: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, //Egypt
  ES: {}, // Spain
  FI: {}, // Finland
  FO: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 10 },
      branchCode: { minLength: 4, maxLength: 4 },
      iban: { minLength: 18, maxLength: 18 },
    },
  }, // Faroe Islands
  FR: {}, // France
  GB: {}, // United Kingdom
  GE: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 22, maxLength: 22 },
    },
  }, // Georgia
  GF: {}, // Guiana
  GP: {}, // Guadeloupe
  GR: {}, // Greece
  GT: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 28, maxLength: 28 },
    },
  }, //Guatemala
  HU: {}, // Hungary
  HK: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BANK_CODE,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
      bankCode: { minLength: 3, maxLength: 3 },
      branchCode: { minLength: 3, maxLength: 3 },
    },
  }, //Hong Kong
  HR: {}, // Croatia
  IE: {}, // Ireland
  IS: {}, // Iceland
  IT: {}, // Italy
  IL: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.BRANCH_NAME,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
  }, // Israel
  IN: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.IFSC_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // India
  ID: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Indonesia
  JE: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.SORT_CODE,
    ],
    rules: {
      accountNumber: { minLength: 8, maxLength: 8 },
      iban: { minLength: 22, maxLength: 22 },
    },
  }, // Jersey
  JP: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.BRANCH_CODE,
    ],
    rules: {
      accountNumber: { maxLength: 8 },
      branchCode: { minLength: 3, maxLength: 10, optional: true },
    },
  }, // Japan
  KR: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // South Korea
  KW: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.BANK_ADDRESS,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      iban: { minLength: 30, maxLength: 30 },
    },
  }, //Kuwait
  KZ: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.BIN_OR_IIN,
      BANK_INPUTS.KNP_OR_UPDC,
    ],
    rules: {
      iban: { minLength: 20, maxLength: 20 },
    },
  }, // Kazakhstan
  LI: {}, // Liechtenstein
  LU: {}, // Luxembourg
  LV: {}, // Latvia
  LT: {}, // Lithuania
  MA: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { minLength: 24, maxLength: 24 },
    },
  }, // Morocco
  MC: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 27, maxLength: 27 },
    },
  }, // Monaco
  MD: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
  }, // Moldova
  ME: {}, // Montenegro
  MF: {}, // Saint Martin
  MT: {}, // Malta
  MQ: {}, // Martinique
  MX: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.CLABE,
      BANK_INPUTS.TAX,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      tax: { minLength: 12, maxLength: 18 },
      accountNumber: { maxLength: 18 },
    },
  }, // Mexico
  MY: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Malaysia
  NC: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    ibanCountryCode: 'FR',
    rules: {
      accountNumber: { maxLength: 10 },
    },
  }, // New Caledonia
  NL: {}, // Netherlands
  NO: {}, // Norway
  NG: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BANK_ADDRESS,
      BANK_INPUTS.NUBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 10 },
    },
  }, // Nigeria
  OM: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Oman
  NZ: {
    fields: [BANK_INPUTS.ACCOUNT_NUMBER, BANK_INPUTS.SWIFT],
    rules: {
      accountNumber: { minLength: 15, maxLength: 16 },
    },
  },
  // New Zealand
  PE: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
      BANK_INPUTS.TAX,
    ],
    rules: {
      accountNumber: { minLength: 20, maxLength: 20 },
      tax: { minLength: 1, maxLength: 15 },
    },
  },
  // Peru
  PH: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.NATIONALITY,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 25 },
      branchCode: { minLength: 3, maxLength: 10 },
    },
  }, // Philippines
  PL: {}, // Poland
  QA: {
    fields: [BANK_INPUTS.BANK_NAME, BANK_INPUTS.IBAN, BANK_INPUTS.SWIFT],
    rules: {
      iban: { minLength: 29, maxLength: 29 },
    },
  }, // Qatar
  PM: {}, // Saint Pierre and Miquelon
  PT: {}, // Portugal
  JO: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.PAYMENT_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      iban: { minLength: 30, maxLength: 30 },
    },
  },
  // Jordan
  RE: {}, // Réunion
  RO: {}, // Romania
  RS: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Serbia
  SA: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.BANK_ADDRESS,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      iban: { minLength: 24, maxLength: 24 },
    },
  }, // Saudi Arabia,
  SN: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 24 },
    },
  }, // Senegal
  SE: {}, // Sweden,
  SG: { fields: [BANK_INPUTS.ACCOUNT_NUMBER, BANK_INPUTS.SWIFT] }, // Singapore
  SI: {}, // Slovenia
  SK: {}, // Slovakia
  SM: {}, // San Marino
  TW: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Taiwan
  TH: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
    },
  }, // Thailand
  TR: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.IBAN,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 19 },
      branchCode: { minLength: 3, maxLength: 5 },
      iban: { minLength: 26, maxLength: 26 },
    },
  }, // Turkey
  UA: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
  }, // Ukraine
  US: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.ABA,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 17 },
    },
  }, // USA
  UY: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.TAX,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 18 },
      tax: { minLength: 8, maxLength: 12 },
    },
  }, // Uruguay
  VN: {
    fields: [
      BANK_INPUTS.BANK_NAME,
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 20 },
    },
  }, // Vietnam
  XK: {}, // Kosovo
  YT: {}, // Mayotte
  ZA: {
    fields: [
      BANK_INPUTS.ACCOUNT_NUMBER,
      BANK_INPUTS.BRANCH_CODE,
      BANK_INPUTS.SWIFT,
    ],
    rules: {
      accountNumber: { maxLength: 128 },
      branchCode: { minLength: 6, maxLength: 6 },
    },
  }, // South Africa
};

export default paymentsFieldsConfig;
