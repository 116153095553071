import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import * as Sentry from '@sentry/react';

import {
  Image,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  MenuGroup,
  Text,
  Box,
} from '@chakra-ui/react';

import userIcon from 'assets/images/user-icon.svg';
import {
  MyAccountTab,
  MyAccountEvents,
} from 'components/MyAccountView/MyAccountView';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import queryClient from 'queryClient';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useSignOut } from '@airhelp/session';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot';
import { NOTIFICATION_DISMISSED_COOKIE } from 'config/cookies';
import { createCookieOptions } from 'utils/cookies';
import { destroySession } from '@airhelp/webapp';
import { webappClient } from 'api/clients/clients';

interface MenuItemProps {
  eventName: string;
  key: string;
  label: string;
  isActive: boolean;
  path: string;
  showNotification: boolean;
}

const UserAction: React.FC = () => {
  const { t } = useTranslation();
  const { setLoginData, subPageType } = useContext(TrackingContext);
  const { trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();
  const { userProfile } = useMyProfile();

  const onLogout = async () => {
    trackCtaClick('logout', 'user menu');
    await destroySession({ client: webappClient });
    queryClient.removeQueries();
    authLogout();
  };

  const { mutate: authLogout } = useSignOut({
    onSuccess: async () => {
      setLoginData(null);
      Sentry.setUser(null);
      navigate({ pathname: generatePath(PAGE_PATHS.LOGIN_PAGE) });
    },
  });

  const showSpecialOffer = userProfile?.policy?.membership?.isEssential;
  const showPaymentManagement =
    userProfile?.policy.membership?.showPaymentManagementPage;

  const displayPulsingDot =
    showSpecialOffer &&
    !location.pathname.includes(PAGE_PATHS.MY_ACCOUNT_PAGE) &&
    !cookie.load(NOTIFICATION_DISMISSED_COOKIE);

  const myAccountMenuItems: MenuItemProps[] = useMemo(() => {
    const tabsArray: MenuItemProps[] = [];

    if (showSpecialOffer) {
      tabsArray.push({
        eventName: MyAccountEvents.SPECIAL_OFFER,
        key: MyAccountTab.SPECIAL_OFFER,
        label: t('common.special_offer'),
        isActive: location.pathname.includes(MyAccountTab.SPECIAL_OFFER),
        path: PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE,
        showNotification: !cookie.load(NOTIFICATION_DISMISSED_COOKIE),
      });
    }

    tabsArray.push(
      {
        eventName: MyAccountEvents.DETAILS,
        key: MyAccountTab.DETAILS,
        label: t('common.details'),
        isActive: location.pathname.includes(MyAccountTab.DETAILS),
        path: PAGE_PATHS.MY_ACCOUNT.DETAILS_PAGE,
        showNotification: false,
      },
      {
        eventName: MyAccountEvents.BENEFITS,
        key: MyAccountTab.BENEFITS,
        label: t('common.plan_and_benefits'),
        isActive: location.pathname.includes(MyAccountTab.BENEFITS),
        path: PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE,
        showNotification: false,
      },
    );

    if (showPaymentManagement) {
      tabsArray.push({
        eventName: MyAccountEvents.BILLING,
        key: MyAccountTab.BILLING,
        label: t('payment_management.payment'),
        isActive: location.pathname.includes(MyAccountTab.BILLING),
        path: PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE,
        showNotification: false,
      });
    }

    tabsArray.push({
      eventName: MyAccountEvents.NOTIFICATIONS,
      key: MyAccountTab.NOTIFICATIONS,
      label: t('common.notifications'),
      isActive: location.pathname.includes(MyAccountTab.NOTIFICATIONS),
      path: PAGE_PATHS.MY_ACCOUNT.NOTIFICATIONS_PAGE,
      showNotification: false,
    });

    return tabsArray;
  }, [t, location, showSpecialOffer]);

  return (
    <Menu
      isLazy
      closeOnBlur
      closeOnSelect
      autoSelect={false}
      onOpen={() => {
        trackCtaClick('user menu', subPageType);
      }}
    >
      <MenuButton data-testid="toggle-user-menu" position="relative">
        <Image width={8} height={8} src={userIcon} alt="" />
        {displayPulsingDot ? (
          <Box
            width="8px"
            height="8px"
            borderRadius="50%"
            bottom="1px"
            right="1px"
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box position="relative">
              <PulsatingNotificationDot width="6px" height="6px" />
            </Box>
          </Box>
        ) : null}
      </MenuButton>
      <MenuList
        minWidth="165px"
        backgroundColor="greyscale.100"
        data-testid="user-action-dropdown"
        py={1}
        px={1}
      >
        <MenuGroup
          color="primary.900"
          fontWeight="500"
          fontSize="m"
          title={t('my_account.logged_as')}
          ms={5}
          mt={3}
          mb={1}
        >
          <Text px={5} pb={3} my={0} fontSize="m">
            {userProfile?.email}
          </Text>
        </MenuGroup>
        <MenuDivider mx={-1} my={1} />
        <MenuGroup
          color="primary.900"
          fontWeight="500"
          fontSize="m"
          title={t('my_account.header')}
          ms={6}
          mt={4}
        >
          {myAccountMenuItems.map((menuItem) => (
            <Link
              role="button"
              to={generatePath(menuItem.path)}
              onClick={() => {
                trackCtaClick(menuItem.eventName, 'user menu');
                if (menuItem.showNotification) {
                  cookie.save(
                    NOTIFICATION_DISMISSED_COOKIE,
                    Date.now(),
                    createCookieOptions(),
                  );
                }
              }}
              data-testid={`menu-item-${menuItem.key}`}
              key={`menu-item-${menuItem.key}`}
            >
              <MenuItem
                px={6}
                py={2}
                borderRadius="md"
                _hover={{
                  backgroundColor: 'greyscale.300',
                }}
              >
                {menuItem.label}

                {menuItem.showNotification ? (
                  <Box position="relative">
                    <PulsatingNotificationDot width="6px" height="6px" />
                  </Box>
                ) : null}
              </MenuItem>
            </Link>
          ))}
        </MenuGroup>
        <MenuDivider mx={-1} my={1} />

        <MenuItem
          as={ChakraLink}
          role="menuitem"
          onClick={onLogout}
          data-testid="logout-link"
          fontWeight="normal"
          px={6}
          py={2}
          borderRadius="md"
          _hover={{
            backgroundColor: 'greyscale.300',
            textDecoration: 'none',
            color: 'primary.900',
          }}
          _focus={{ boxShadow: 'none' }}
        >
          {t('authentication.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserAction;
