import React from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '@chakra-ui/react';

import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { BANK_INPUTS, InsuranceType } from '@airhelp/plus';
import { useTrackEvent } from 'utils/tracking/hooks';
import { camelCase, omit } from 'lodash';

const AirBundlePayoutActions = ({
  form,
  uploadInProgress,
  dataDetailsCollected,
  setDataDetailsCollected,
  onSubmit,
  insuranceType,
  submitError,
  isDisabled,
}: {
  form: UseFormReturn;
  uploadInProgress?: boolean;
  dataDetailsCollected?: boolean;
  setDataDetailsCollected?: (value: boolean) => void;
  onSubmit: (data) => void;
  insuranceType: InsuranceType;
  submitError: boolean;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { setValue, watch, formState, handleSubmit, getValues, reset } = form;
  const { activeStep, setActiveStep } = useLayout();

  const resetBankFields = () => {
    const currentValues = getValues();
    const newValues = omit(currentValues, Object.values(BANK_INPUTS));
    reset(newValues);
  };

  const handleRemoveCountry = () => {
    setValue('country', '');
    resetBankFields();
  };

  const { isSubmitting } = formState;

  const isLoadingPayoutButton = isSubmitting || uploadInProgress;
  const isDisabledPayoutButton =
    isLoadingPayoutButton || submitError || isDisabled;

  const countryCode = watch('country')?.value || '';
  const showUpload = insuranceType != InsuranceType.AIR_LUGGAGE;
  const showBankStep = countryCode && (!showUpload || document);
  const pageEventName = `ahp ${camelCase(insuranceType.replace(/_/g, ' '))} page`;

  const handleNextStep = () => {
    if (setDataDetailsCollected) {
      setActiveStep(activeStep + 1);
      setDataDetailsCollected(false);
      trackCtaClick('continue button', pageEventName);
    }
    return null;
  };

  const handleRequest = (data) => {
    handleSubmit(onSubmit)(data);
    trackCtaClick('request payment button', pageEventName);
  };

  const handleBackStep = () => {
    if (setDataDetailsCollected) {
      setActiveStep(activeStep - 1);
      setDataDetailsCollected(true);
    }
    return null;
  };

  return (
    <Stack
      width="100%"
      direction={{ base: 'column-reverse', md: 'row' }}
      justifyContent="right"
      mt={6}
      spacing={5}
    >
      {showBankStep ? (
        <Button
          variant="secondary"
          size="s"
          onClick={handleRemoveCountry}
          isDisabled={isDisabledPayoutButton}
          data-testid="back-button"
        >
          {t('common.back')}
        </Button>
      ) : null}
      {setDataDetailsCollected && !showBankStep && !dataDetailsCollected ? (
        <Button
          variant="secondary"
          size="s"
          onClick={handleBackStep}
          data-testid="back-button"
        >
          {t('common.back')}
        </Button>
      ) : null}
      <Button
        isLoading={isLoadingPayoutButton}
        isDisabled={isDisabledPayoutButton}
        onClick={
          dataDetailsCollected ? handleSubmit(handleNextStep) : handleRequest
        }
        size="s"
        data-testid={
          dataDetailsCollected
            ? 'save-data-details-button'
            : 'request-payment-button'
        }
      >
        {showBankStep
          ? t('instant_cash.payout.request_payment')
          : t('common.continue')}
      </Button>
    </Stack>
  );
};

export default AirBundlePayoutActions;
