import { AirBundleDocumentFile } from '@airhelp/plus';

import { TranslationKey } from 'types/i18n';

interface AirPayoutDocument {
  name: string;
  tKey: TranslationKey;
}

const COMMON_DOCUMENTS: AirPayoutDocument[] = [
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
];

export const CANCELED_AIR_PAYOUT_DOCUMENTS: AirPayoutDocument[] = [
  ...COMMON_DOCUMENTS,
  {
    name: `${AirBundleDocumentFile.BOOKING}File`,
    tKey: 'air_luggage.luggage_details.documents.booking_confirmation',
  },
  {
    name: `${AirBundleDocumentFile.CANCELATION}File`,
    tKey: 'air_luggage.luggage_details.documents.cancel_confirmation_document',
  },
];

export const DELAYED_AND_DIVERTED_AIR_PAYOUT_DOCUMENTS: AirPayoutDocument[] = [
  ...COMMON_DOCUMENTS,
  {
    name: `${AirBundleDocumentFile.BOARDING}File`,
    tKey: 'air_luggage.luggage_details.documents.boarding_pass',
  },
];
