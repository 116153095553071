import { uploadAirBundleDocument, UploadDocumentsParams } from '@airhelp/plus';
import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';

const useUploadAirBundleDocument = () => {
  const useUploadAirBundleDocumentMutation = useMutation({
    mutationFn: (params: UploadDocumentsParams) =>
      uploadAirBundleDocument({
        client: ahplusClient,
        params,
      }),
  });

  return useUploadAirBundleDocumentMutation;
};

export default useUploadAirBundleDocument;
