import * as ClaimsPages from 'pages/Claims';

export const ClaimsRoutes = {
  path: 'claims',
  children: [
    {
      index: true,
      element: <ClaimsPages.IndexPage />,
    },
    {
      path: ':id',
      element: <ClaimsPages.ClaimPage />,
    },
    {
      path: ':id/progress',
      element: <ClaimsPages.ClaimProgressPage />,
    },
    {
      path: ':id/compensation',
      element: <ClaimsPages.ClaimCompensationPage />,
    },
    {
      path: ':id/documents',
      element: <ClaimsPages.ClaimDocumentsPage />,
    },
    {
      path: ':id/details',
      element: <ClaimsPages.ClaimDetailsPage />,
    },
    {
      path: ':id/history',
      element: <ClaimsPages.ClaimHistoryPage />,
    },
  ],
};
