import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box } from '@chakra-ui/react';

import config from 'config';
import { EditedJourneyContext } from 'contexts';

import AddedFellowPassenger from './AddedFellowPassenger';
import AddFellowPassenger from './AddFellowPassenger';

const FellowPassengers = () => {
  const { t } = useTranslation();
  const { editedItinerary } = useContext(EditedJourneyContext);
  const { fellowPassengers } = editedItinerary;

  const allowedFellowPassengersCountReached =
    fellowPassengers.length === config.maxFellowPassengers;
  const T_PREFIX = 'funnels.add_new_flight.follow_passengers';

  return (
    <Box borderRadius="xl" backgroundColor="greyscale.300">
      <Box maxW="410px">
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" />}
          variant="secondary"
          mb={6}
        >
          {t(`${T_PREFIX}.add_your_fellow_passengers_description`)}
        </InfoBox>
        {fellowPassengers.map((passenger) => (
          <AddedFellowPassenger key={passenger.id} passenger={passenger} />
        ))}
        <AddFellowPassenger />
        {allowedFellowPassengersCountReached ? (
          <InfoBox
            mt={6}
            icon={<Info color="danger.500" />}
            variant="danger"
            data-testid="infobox-max-fellow-passengers"
          >
            {t(`${T_PREFIX}.max_fellow_passengers`, {
              number: config.maxFellowPassengers,
            })}
          </InfoBox>
        ) : null}
      </Box>
    </Box>
  );
};

export default FellowPassengers;
