import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Check, Info } from '@airhelp/icons';
import { Input } from '@airhelp/react';
import { Box, InputGroup, InputRightElement, Button } from '@chakra-ui/react';

import { ErrorBadge } from 'elements';
import { nameMaxLength, nameMinLength } from 'utils/validations';

export interface PromoCodeValue {
  promoCode: string;
}

interface PromoCodeInput {
  form: UseFormReturn<PromoCodeValue>;
  onSubmit: (data: PromoCodeValue) => void;
  promoCode?: string | number | null;
  setApiError: (value: boolean) => void;
  apiError: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  setPromoCodeUsed: (value: boolean) => void;
}

const PromoCodeInput: React.FC<PromoCodeInput> = ({
  form,
  onSubmit,
  promoCode,
  setApiError,
  apiError,
  isDisabled,
  isLoading,
  setPromoCodeUsed,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = form;

  const buttonColorStyles = promoCode ? 'success.600' : 'primary.500';

  return (
    <Box bg="greyscale.100" borderRadius="lg" w="100%" px={6} py={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({
            field: { onChange, name, value },
            fieldState: { invalid, error },
          }) => (
            <InputGroup size="lg">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                size="lg"
                label={t('pricing.discount_code')}
                name={name}
                value={value}
                type="text"
                placeholder={t('pricing.enter_discount_code')}
                width="100%"
                borderColor="greyscale.500"
                textOverflow="ellipsis"
                paddingRight={promoCode ? '180px' : '130px'}
                onChange={(e) => {
                  setApiError(false);
                  setPromoCodeUsed(false);
                  onChange(e.target.value.toUpperCase());
                }}
                data-testid="promo-code-input"
              />
              <InputRightElement width="auto" top="29px">
                {apiError ? (
                  <Info boxSize={4} color="danger.700" me={5} />
                ) : null}
                <Button
                  variant="primary"
                  backgroundColor={buttonColorStyles}
                  _hover={{ backgroundColor: buttonColorStyles }}
                  onClick={handleSubmit(onSubmit)}
                  borderStartRadius="none"
                  whiteSpace="nowrap"
                  size="m"
                  leftIcon={
                    promoCode ? (
                      <Check width={5} height={5} name="Check" />
                    ) : undefined
                  }
                  maxHeight="50px"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  _focus={{ boxShadow: 'none' }}
                  data-testid="promo-code-button"
                >
                  {promoCode ? t('common.added') : t('common.add')}
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
          rules={{
            minLength: {
              value: nameMinLength,
              message: t('errors.too_short', {
                min: nameMinLength,
                attribute: t('pricing.discount_code'),
              }),
            },
            maxLength: {
              value: nameMaxLength,
              message: t('errors.too_long', {
                max: nameMaxLength,
                attribute: t('pricing.discount_code'),
              }),
            },
          }}
          name="promoCode"
          control={control}
          defaultValue=""
        />
        {apiError ? (
          <ErrorBadge data-testid="promo-code-error">
            {t('errors.not_a_valid_code')}
          </ErrorBadge>
        ) : null}
      </form>
    </Box>
  );
};

export default PromoCodeInput;
