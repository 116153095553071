import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';
import {
  updateAirLuggageJourneys,
  UpdateAirLuggagesForJourneyParams,
} from '@airhelp/plus';

const useUpdateAirLuggageJourneys = () => {
  const queryClient = useQueryClient();

  const useUpdateAirLuggageJourneysMutation = useMutation({
    mutationFn: (params: UpdateAirLuggagesForJourneyParams) =>
      updateAirLuggageJourneys({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['journeys'],
        }),
        queryClient.invalidateQueries({
          queryKey: [
            'journey',
            'air-luggages',
            { journeyId: params.journeyId },
          ],
        }),
        queryClient.setQueryData(['journey', { id: params.journeyId }], {
          ...data,
        }),
      ]);
    },
  });

  return useUpdateAirLuggageJourneysMutation;
};

export default useUpdateAirLuggageJourneys;
