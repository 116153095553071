import { Locale, locales } from 'contexts/LocaleContext/locales';

const setDirectionalityByLocale = (locale: Locale) => {
  const lng = locales[locale];
  document.documentElement.dir = lng.rtl ? 'rtl' : 'ltr';
};

export const setDirectionalityHtmlAttribute = (locale: Locale) => {
  if (locale) {
    setDirectionalityByLocale(locale);
  }
};
