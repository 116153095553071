import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import BackToTripButton from 'components/Journey/BackToTripButton';
import FunnelLayout from 'components/Layouts/FunnelLayout';
import { PAGE_PATHS } from 'config/routes';
import { AirBundleFunnel } from 'funnels/AirBundleFunnel';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { InsuranceType } from '@airhelp/plus';
import useFeatureFlags from 'hooks/api/useFeatureFlags';

interface PageParams extends Record<string, string | undefined> {
  id: string;
}

const AirBundlePage = ({ insuranceType }: { insuranceType: InsuranceType }) => {
  useProtectedPage();

  const { t } = useTranslation();
  const { id } = useParams<PageParams>();
  const { data: featureFlags } = useFeatureFlags();

  const { pathname } = useLocation();

  if (!id) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  const airLuggageConfirmationPage = generatePath(
    PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_CONFIRMATION_PAGE,
    {
      id: id.toString(),
    },
  );

  const airPayoutConfirmationPage = generatePath(
    PAGE_PATHS.JOURNEY.AIR_PAYOUT.PAYOUT_CONFIRMATION_PAGE,
    {
      id: id.toString(),
    },
  );

  const disableAssistantBadge =
    airPayoutConfirmationPage === pathname ||
    airLuggageConfirmationPage === pathname;

  const veriffFlag = featureFlags?.veriff_enabled;

  const secondStep = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return 'air_luggage.luggage_details.luggage_details';
      case InsuranceType.AIR_PAYOUT:
      default:
        return 'common.documents';
    }
  })();

  let steps: string[] = [];
  if (veriffFlag) {
    steps = [
      t('common.eligibility_check'),
      t(secondStep),
      t('instant_cash.payout.bank_details'),
      t('instant_cash.payout.verification'),
      t('common.payment_confirmation'),
    ];
  } else {
    steps = [
      t('common.eligibility_check'),
      t(secondStep),
      t('instant_cash.payout.bank_details'),
      t('common.payment_confirmation'),
    ];
  }

  return (
    <FunnelLayout
      disableAssistantBadge={disableAssistantBadge}
      disableCurrencySwitcher
      steps={steps}
      sideHeader={
        <Box mb={10}>
          <BackToTripButton dataTestId="back-to-journey-desktop-button" />
        </Box>
      }
      headerStart={
        <Box alignSelf="center" me={4}>
          <BackToTripButton
            variantBreakpoint="xl"
            dataTestId="back-to-journey-mobile-button"
          />
        </Box>
      }
    >
      <AirBundleFunnel journeyId={id} insuranceType={insuranceType} />
    </FunnelLayout>
  );
};

export default AirBundlePage;
