import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { AirLuggagePaymentPax } from '@airhelp/plus';

import UserCard from 'components/Journey/AirBundle/UserCard';
import {
  type Action,
  AirLuggagePaymentTypes,
} from 'reducers/airLuggagePaymentReducer/airLuggagePaymentReducer';

import DetailsForm from '../DetailsForm';
import useGetAirLuggageStatusBadge from '../getAirLuggageStatusBadge';
import { useTrackEvent } from 'utils/tracking/hooks';
import { AirLuggageJourney } from 'models/journey/AirLuggageJourney';

interface IComponent {
  airLuggageJourney: AirLuggageJourney;
  dispatch: React.Dispatch<Action>;
  reducerDataById: Record<number, AirLuggagePaymentPax>;
  setFormsValidity: (id: number, valid: boolean) => void;
  isMultiPax: boolean;
}

const LuggageDetails: React.FC<IComponent> = ({
  airLuggageJourney,
  dispatch,
  reducerDataById,
  isMultiPax,
  setFormsValidity,
}) => {
  const { trackCtaClick } = useTrackEvent();
  const form = useForm({ mode: 'onSubmit' });
  const { handleSubmit } = form;

  const { payoutAllowed, status, id, maxBags } = airLuggageJourney;
  const paxFilled = !!reducerDataById[id];
  const { isOpen, onToggle, onClose } = useDisclosure({
    defaultIsOpen: !paxFilled && !isMultiPax,
  });
  const statusBox = useGetAirLuggageStatusBadge(status);
  const oneMaxBag = maxBags === 1;

  const handleAddPax = (data) => {
    const { numberOfLuggages, payoutEligibleItineraryId } = data;
    dispatch({
      type: AirLuggagePaymentTypes.EDIT_PASSENGER,
      payload: {
        ...data,
        id,
        numberOfLuggages: oneMaxBag ? 1 : numberOfLuggages.value,
        payoutEligibleItineraryId: payoutEligibleItineraryId.value,
      },
    });
    onClose();
    setFormsValidity(id, true);
    trackCtaClick('documents added', 'ahp airLuggage page');
  };

  if (!payoutAllowed) {
    return (
      <Box mt={4}>
        <UserCard
          airBundleJourney={airLuggageJourney}
          payoutAllowed={!!payoutAllowed}
          statusBox={statusBox}
        />
      </Box>
    );
  }

  return (
    <Flex
      flexDirection="column"
      border={isOpen ? '1px solid' : 'none'}
      borderColor="primary.500"
      borderRadius="xl"
      mt={4}
      data-testid="luggage-details-container"
    >
      <UserCard
        airBundleJourney={airLuggageJourney}
        payoutAllowed={payoutAllowed}
        isOpen={isOpen}
        handleEdit={onToggle}
        statusBox={statusBox}
        canEdit
        paxFilled={paxFilled}
      />
      <Collapse in={isOpen} animateOpacity key={airLuggageJourney.id}>
        <DetailsForm
          form={form}
          airLuggageJourney={airLuggageJourney}
          reducerDataById={reducerDataById}
          handleAddPax={handleSubmit(handleAddPax)}
          setFormsValidity={setFormsValidity}
          canEdit
        />
      </Collapse>
    </Flex>
  );
};

export default LuggageDetails;
