import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';
import { updateJourney, UpdateJourneyParams } from '@airhelp/plus';

const useUpdateJourney = () => {
  const queryClient = useQueryClient();

  const useDeleteJourneyMutation = useMutation({
    mutationFn: (params: UpdateJourneyParams) =>
      updateJourney({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data) => {
      await queryClient.setQueryData(['journey', { id: data.id }], {
        ...data,
      });
      await queryClient.invalidateQueries({
        queryKey: ['journeys'],
      });
    },
  });

  return useDeleteJourneyMutation;
};

export default useUpdateJourney;
