import { getPaymentStatus, GetPaymentStatusParams } from '@airhelp/plus';
import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';

const usePaymentStatus = () => {
  const usePaymentStatusMutation = useMutation({
    mutationFn: (params: GetPaymentStatusParams) =>
      getPaymentStatus({
        client: ahplusClient,
        params,
      }),
  });

  return usePaymentStatusMutation;
};

export default usePaymentStatus;
