import React, { useEffect, useState } from 'react';
import {
  generatePath,
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import track from 'react-tracking';

import AirLuggagePayoutUpdateView from 'components/Journey/AirLuggage/PayoutUpdateView';
import AirPayoutPayoutUpdateView from 'components/Journey/AirPayout/PayoutUpdateView';
import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import ErrorPageView from 'elements/ErrorView';
import { type ComponentPageProps } from 'funnels/AirBundleFunnel';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectFailed } from 'utils/airBundle/airBundle';
import { InsuranceType } from '@airhelp/plus';
import { pascalCase } from 'utils/pascalCase';
import useJourney from 'hooks/api/journey/useJourney';
import useFetchAirLuggageJourney from 'hooks/api/airBundle/useFetchAirLuggageJourney';
import useFetchAirPayoutJourney from 'hooks/api/airBundle/useFetchAirPayoutJourney';

const PayoutUpdatePage: React.FC<ComponentPageProps> = ({
  journeyId,
  insuranceType,
}) => {
  const navigate = useNavigate();
  const { setActiveStep } = useLayout();
  const { isLoading: isLoadingUserProfile } = useFetchMyProfile();
  const { data: journeyData, isLoading: isLoadingJourney } = useJourney({
    id: parseInt(journeyId),
  });
  const [searchParams] = useSearchParams();
  const transactionReference = searchParams.get('tr');

  const [isStepComplete, setIsStepComplete] = useState(false);
  const [allowUpdate, setAllowUpdate] = useState<boolean | undefined>(
    undefined,
  );

  useEffectOnce(() => {
    setActiveStep(2);
  });

  const queryData = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return useFetchAirLuggageJourney({
          journeyId: parseInt(journeyId),
        });
      case InsuranceType.AIR_PAYOUT:
      default:
        return useFetchAirPayoutJourney({
          journeyId: parseInt(journeyId),
        });
    }
  })();

  useEffect(() => {
    if (allowUpdate === undefined && queryData.data) {
      setAllowUpdate(
        selectFailed(queryData.data, transactionReference, insuranceType)
          .length > 0,
      );
    }
  }, [allowUpdate, setAllowUpdate, queryData.data]);

  if (queryData.isError) {
    return <ErrorPageView goBack={() => navigate(-1)} />;
  }

  if (
    allowUpdate == undefined ||
    !queryData.data ||
    isLoadingUserProfile ||
    isLoadingJourney ||
    !journeyData
  ) {
    return <Loader />;
  }

  if (!allowUpdate) {
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, {
            id: journeyId,
          }),
        }}
      />
    );
  }

  const confirmationPath = PAGE_PATHS.JOURNEY[insuranceType.toUpperCase()]
    .PAYOUT_CONFIRMATION_PAGE as string;

  const PayoutUpdateView = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return AirLuggagePayoutUpdateView;
      case InsuranceType.AIR_PAYOUT:
      default:
        return AirPayoutPayoutUpdateView;
    }
  })();

  if (isStepComplete) {
    return (
      <Navigate
        to={{ pathname: generatePath(confirmationPath, { id: journeyId }) }}
      />
    );
  }
  return (
    <PayoutUpdateView
      journeyListData={selectFailed(
        queryData.data,
        transactionReference,
        insuranceType,
      )}
      journeyData={journeyData}
      onPayoutSuccessful={(successful: boolean) => {
        setIsStepComplete(successful);
      }}
    />
  );
};

export default track({
  page_type: (props: ComponentPageProps) =>
    `Journey.${pascalCase(props.insuranceType)}.PayoutUpdate`,
})(PayoutUpdatePage);
