import React from 'react';

import { VStack } from '@chakra-ui/react';

import { type UserProfile } from 'models/userProfile/UserProfile';

import FeaturesNotIncluded from './FeaturesNotIncluded';
import ManageYourPlan from './ManageYourPlan';
import YourPlanBenefits from './YourPlanBenefits';
import YourPlanInfo from './YourPlanInfo';
import YourTrips from './YourTrips/YourTrips';
import { Journey } from 'models/journey/Journey';

const BenefitsView: React.FC<{
  userProfile: UserProfile;
  journeys: Journey[];
}> = ({ userProfile, journeys }) => {
  const policy = userProfile.policy;

  const hasUserOldPlans = Boolean(
    policy.membership?.isComplete || policy.membership?.isEssential,
  );

  // show manage plan scetion only for b2c and not (internal or external) clients
  const showManagePlanSection = policy.membership?.fromCustomer;

  return (
    <VStack alignItems="stretch" spacing={4}>
      <YourPlanInfo userProfile={userProfile} />
      {policy.trip && journeys.length > 0 ? (
        <YourTrips journeys={journeys} />
      ) : null}
      {policy.membership?.active && policy.benefits ? (
        <>
          <YourPlanBenefits
            oldPlans={hasUserOldPlans}
            benefits={policy.benefits}
            eSim={policy.perks.esim}
          />
          <FeaturesNotIncluded />
          {showManagePlanSection ? (
            <ManageYourPlan userProfile={userProfile} />
          ) : null}
        </>
      ) : null}
    </VStack>
  );
};

export default BenefitsView;
