import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { fetchFeatureFlags, FetchFeatureFlagsResponse } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { useQuery } from '@tanstack/react-query';

const useFeatureFlags = (
  options?: ApiQueryOptions<FetchFeatureFlagsResponse>,
) => {
  return useQuery({
    queryKey: ['feature-flags'],
    queryFn: () => fetchFeatureFlags({ client: ahplusClient }),
    ...options,
  });
};

export default useFeatureFlags;
