import { useTranslation } from 'react-i18next';

import { Check } from '@airhelp/icons';
import { AirPayoutStatus } from '@airhelp/plus';

const useGetAirPayoutStatusBadge = (status?: AirPayoutStatus) => {
  const { t } = useTranslation();
  switch (status) {
    case AirPayoutStatus.ACTIVE:
      return {
        label: t('common.active'),
      };
    case AirPayoutStatus.PAYOUT_SUCCESSFUL:
      return {
        label: t('common.paid'),
        icon: Check,
        style: {
          color: 'greyscale.100',
          backgroundColor: 'success.500',
        },
      };
    case AirPayoutStatus.ELIGIBLE:
      return {
        label: t('air_payout.card.badge.eligible'),
        style: {
          color: 'success.500',
        },
      };
    case AirPayoutStatus.PAYOUT_FAILED:
    case AirPayoutStatus.PAYOUT_RETURNED:
      return {
        label: t('air_payout.card.badge.resubmit_bank_details'),
        style: {
          color: 'warning.500',
        },
      };
    case AirPayoutStatus.INELIGIBLE:
    case AirPayoutStatus.MEMBERSHIP_NOT_RENEWED:
    case AirPayoutStatus.BLOCKED:
    case AirPayoutStatus.UNREGISTERED:
    case AirPayoutStatus.BLOCKED_UNREGISTERED:
    case AirPayoutStatus.CANCELED_BEFORE_REGISTRATION:
      return {
        label: t('air_payout.card.badge.not_eligible'),
      };
    case AirPayoutStatus.REJECTED:
      return {
        label: t('air_luggage.card.badge.claim_rejected'),
        style: {
          color: 'warning.500',
          backgroundColor: 'warning.100',
        },
      };
    case AirPayoutStatus.PROCESSING_PAYMENT:
    case AirPayoutStatus.MANUAL_VERIFICATION_PENDING:
      return {
        label: t('common.processing_payment'),
      };
    case AirPayoutStatus.EXPIRED:
      return {
        label: t('air_payout.card.badge.claim_expired'),
      };
    default:
      return {
        label: t('common.active'),
      };
  }
};

export default useGetAirPayoutStatusBadge;
