import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

enum ClaimTabs {
  PROGRESS = 'progress',
  COMPENSATION = 'compensation',
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  HISTORY = 'history',
}

interface NavigationItem {
  eventName?: string;
  key: string;
  label: string;
  isActive: boolean;
  path: string;
  showNotification: boolean;
}

interface NavigationProps {
  showDocumentNotification: boolean;
}

export function Navigation({ showDocumentNotification }: NavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: claimId } = useParams();
  const tabs: NavigationItem[] = [
    {
      key: ClaimTabs.PROGRESS,
      label: 'Claim Progress',
      isActive: location.pathname.includes(ClaimTabs.PROGRESS),
      path: PAGE_PATHS.CLAIM.PROGRESS_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.COMPENSATION,
      label: 'Compensation',
      isActive: location.pathname.includes(ClaimTabs.COMPENSATION),
      path: PAGE_PATHS.CLAIM.COMPENSATION_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.DOCUMENTS,
      label: 'Documents',
      isActive: location.pathname.includes(ClaimTabs.DOCUMENTS),
      path: PAGE_PATHS.CLAIM.DOCUMENTS_PAGE,
      showNotification: showDocumentNotification,
    },
    {
      key: ClaimTabs.DETAILS,
      label: 'Details',
      isActive: location.pathname.includes(ClaimTabs.DETAILS),
      path: PAGE_PATHS.CLAIM.DETAILS_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.HISTORY,
      label: 'History',
      isActive: location.pathname.includes(ClaimTabs.HISTORY),
      path: PAGE_PATHS.CLAIM.HISTORY_PAGE,
      showNotification: false,
    },
  ];

  return (
    <Tabs index={tabs.findIndex((tab) => tab.isActive)} variant="soft-rounded">
      <TabList gap={2} justifyContent="center">
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            onClick={() =>
              navigate(
                generatePath(tab.path, {
                  id: claimId,
                }),
              )
            }
            fontWeight="medium"
            border="2px solid transparent"
            boxSizing="border-box"
            p={2}
            fontSize="sm"
            bgColor="greyscale.300"
            color="greyscale.600"
            _selected={{
              color: 'primary.900',
              bgColor: 'greyscale.100',
              borderColor: 'transparent',
            }}
            _active={{
              borderColor: tab.isActive ? '#DEE5ED' : 'transparent',
              bgColor: tab.isActive ? 'greyscale.100' : 'greyscale.400',
              color: 'primary.900',
            }}
          >
            {tab.label}
            {tab.showNotification ? (
              <Box position="relative">
                <PulsatingNotificationDot width="6px" height="6px" />
              </Box>
            ) : null}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
