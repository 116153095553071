import { QueryClient } from '@tanstack/react-query';
import { isAuthError, isNotFoundError } from 'api/http/http';
import { ZodError } from 'zod';

const MAX_RETRY_COUNT = 2;

export const queryRetry = (failureCount: number, error: unknown): boolean => {
  if (
    isAuthError(error) ||
    isNotFoundError(error) ||
    error instanceof ZodError
  ) {
    return false;
  }

  return failureCount < MAX_RETRY_COUNT;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      // 10 minutes cache
      gcTime: 10 * 60 * 1000,
      retry: queryRetry,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
