import React from 'react';
import { useTranslation } from 'react-i18next';

import { WiFi, Seat, Cafe, Mute } from '@airhelp/icons';
import { Collapsible } from '@airhelp/react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';

import { Card } from 'elements';
import { MIN_CARD_WIDTH } from 'config/lounge';

export interface ILoungeOffer {
  id: string;
  icon: React.ReactNode;
  title: string;
}

interface ICompoent {
  onToggle: (isToggle: boolean) => void;
}

const LoungesOffer: React.FC<ICompoent> = ({ onToggle }) => {
  const { t } = useTranslation();

  const loungesOfferList: ILoungeOffer[] = [
    {
      id: 'wifi',
      icon: <WiFi boxSize={6} />,
      title: t('lounges.offer.wifi'),
    },
    {
      id: 'seats',
      icon: <Seat boxSize={6} />,
      title: t('lounges.offer.seats'),
    },
    {
      id: 'food',
      icon: <Cafe boxSize={6} />,
      title: t('lounges.offer.food'),
    },
    {
      id: 'peace',
      icon: <Mute boxSize={6} />,
      title: t('lounges.offer.peace'),
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });

  const offersList = (
    <Flex
      display="flex"
      flexWrap="wrap"
      flexDirection={{ base: 'column', md: 'row' }}
      rowGap={5}
      mt={{ base: 4, md: 0 }}
    >
      {loungesOfferList
        ? loungesOfferList.map((item) => (
            <Box
              key={`lounges-offer-${item.id}`}
              display="flex"
              alignItems="flex-start"
              width={{ base: '100%', md: '50%' }}
              minWidth={{ base: 'auto', md: '200px' }}
            >
              <Box
                color="primary.500"
                p={3}
                borderColor="greyscale.400"
                borderWidth="1px"
                borderRadius="lg"
                bgColor="greyscale.100"
              >
                {item.icon}
              </Box>
              <Text fontSize="md" alignSelf="center" ms={5}>
                {item.title}
              </Text>
            </Box>
          ))
        : null}
    </Flex>
  );

  if (isMobile) {
    return (
      <Collapsible
        title={t('lounges.offer.header')}
        borderRadius="lg"
        data-testid="lounges-offer"
        onToggle={onToggle}
      >
        {offersList}
      </Collapsible>
    );
  }
  return (
    <Card
      minWidth={{ base: '100%', lg: MIN_CARD_WIDTH }}
      py={{ base: 5, lg: 10 }}
      px={{ base: 5, lg: 8 }}
      bgColor="greyscale.300"
      borderColor="greyscale.400"
      borderWidth="1px"
      justifyContent="flex-start"
      data-testid="lounges-offer"
      flex="1"
    >
      <Text fontSize="lg" fontWeight="bold" mb={{ base: 5, lg: 8 }}>
        {t('lounges.offer.header')}
      </Text>
      {offersList}
    </Card>
  );
};

export default LoungesOffer;
