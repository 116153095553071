import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';
import {
  fetchBillingManagementSchema,
  updateBillingAddress,
  UpdateBillingAddressParams,
} from '@airhelp/plus';

const useUpdateBillingAddress = () => {
  const queryClient = useQueryClient();

  const useUpdateBillingAddressMutation = useMutation({
    mutationFn: (params: UpdateBillingAddressParams) =>
      updateBillingAddress({
        client: ahplusClient,
        params,
      }),
    onSuccess: async (data, params) => {
      await queryClient.setQueryData(
        ['billing-management'],
        (current: typeof fetchBillingManagementSchema) => ({
          ...current,
          billingAddress: params.payload,
        }),
      );
      await queryClient.invalidateQueries({
        queryKey: ['billing-management'],
      });
    },
  });

  return useUpdateBillingAddressMutation;
};

export default useUpdateBillingAddress;
