import { useParams } from 'react-router-dom';

import { Navigation } from './Navigation/Navigation';
import { useClaim } from '@airhelp/webapp/react';
import { ClaimOverview } from './Overview/Overview';
import { Status } from './Status/Status';
import { Loader } from 'elements';
import { Layout } from './Layout';

export function ClaimProgressView() {
  const { id } = useParams();
  const { data: claim, isLoading } = useClaim({
    params: { id: Number(id) },
  });

  if (!claim) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  const flight = claim.itinerary.flights.at(0);

  // TODO - decide what to do when there is no flight available
  if (!flight) {
    return null;
  }

  const { departureAirport, arrivalAirport } = flight;

  return (
    <Layout>
      <ClaimOverview
        departureAirportCountryCode={departureAirport.countryCode}
        arrivalAirportCountryCode={arrivalAirport.countryCode}
        departureAirportCode={departureAirport.iata}
        arrivalAirportCode={arrivalAirport.iata}
        claimId={claim.id}
        compensation={claim.finalCompensationInPreferredCurrency}
      />
      <Navigation showDocumentNotification={claim.pendingDocumentRequest} />
      <Status />
    </Layout>
  );
}
