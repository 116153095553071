import { Circle } from '@chakra-ui/react';
import { type IconType } from '@airhelp/icons';
import { useNavigate, Path } from 'react-router-dom';

interface RoundedButtonProps {
  label: string;
  Icon: IconType;
  disabled?: boolean;
  onClick?: VoidFunction;
  navigateTo?: string | Partial<Path>;
  dataTestId?: string;
}

export function RoundedButton({ Icon, ...props }: RoundedButtonProps) {
  const navigate = useNavigate();

  const onClick = () => {
    if (props.onClick) {
      return props.onClick();
    }

    if (props.navigateTo) {
      navigate(props.navigateTo);
    }
  };

  return (
    <Circle
      pointerEvents={props.disabled ? 'none' : 'auto'}
      bgColor="greyscale.100"
      color="greyscale.600"
      cursor="pointer"
      _hover={{ bgColor: 'greyscale.300', color: 'greyscale.600' }}
      onClick={onClick}
      aria-labelledby={props.label}
      as={props.navigateTo ? 'a' : 'button'}
      p={2}
      data-testId={props.dataTestId}
    >
      <Icon />
    </Circle>
  );
}
