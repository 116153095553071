import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AirplaneRight,
  ConnectingFlightRight,
  Calendar,
  ConnectingFlightLeft,
} from '@airhelp/icons';
import { Box, Divider, Flex, Tag, TagLabel, Text } from '@chakra-ui/react';

import { journeyUtils } from 'utils';

import AirportCard from './AirportCard';
import FlightStatusBadge from './FlightStatusBadge';
import { Flight } from '@airhelp/plus';

interface IComponent {
  flight: Flight;
  isConnectingFlight: boolean;
  getShowProtectedTag: () => boolean;
}

const Separator = () => {
  return (
    <>
      <Box
        position="relative"
        mt={2}
        mb={12}
        width="100%"
        display={{ base: 'block', md: 'none' }}
      >
        <Box
          bg="greyscale.100"
          transform="rotate(135deg)"
          borderTop="1px solid"
          borderTopColor="greyscale.400"
          borderRight="1px solid"
          borderRightColor="greyscale.400"
          alignItems="center"
          alignSelf="center"
          borderTopRightRadius={3}
          borderBottomLeftRadius={3}
          width={3.5}
          height={3.5}
          position="absolute"
          top={2.5}
          left="50%"
          right="50%"
          ms={-1.5}
        />
        <Divider
          orientation="horizontal"
          borderColor="greyscale.400"
          width="calc(100% + 33px)"
          mx={-4}
          mt={4}
          opacity={1}
        />
      </Box>
      <Box
        bg="greyscale.100"
        transform="rotate(44deg)"
        borderTop="1px solid"
        borderTopColor="greyscale.400"
        borderRight="1px solid"
        borderRightColor="greyscale.400"
        alignItems="center"
        alignSelf="center"
        borderTopRightRadius={3}
        borderBottomLeftRadius={3}
        width={3.5}
        height={3.5}
        me={{ base: 0, md: -1 }}
        display={{ base: 'none', md: 'block' }}
      />
      <Divider
        orientation="vertical"
        borderColor="greyscale.400"
        mx={4}
        ms={-1}
        my={-3}
        height="auto"
        flex="none"
        width="1%"
        opacity={1}
      />
    </>
  );
};

const FlightCard: React.FC<IComponent> = ({
  flight,
  isConnectingFlight,
  getShowProtectedTag,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        borderRadius="lg"
        border="1px solid"
        borderColor="greyscale.400"
        bgColor="greyscale.300"
        mb={4}
      >
        <Flex
          borderTopRightRadius="lg"
          borderTopLeftRadius="lg"
          borderBottom="1px solid"
          borderColor="greyscale.400"
          bgColor="greyscale.300"
          color="primary.900"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          p={{ base: 4, md: 6 }}
        >
          <Flex flexWrap="wrap" flexDirection={{ base: 'column', md: 'row' }}>
            <Box fontWeight="medium" fontSize="lg" color="primary.900" me={5}>
              {flight.airline?.alternativeName || flight.airline?.name} -{' '}
              {`${flight.airline?.code}${flight.flightNumber}`}
            </Box>
            <Flex alignItems="center">
              <Text as="span" me={1} display="inline-flex">
                <Calendar
                  color={{ base: 'greyscale.500', md: 'greyscale.700' }}
                  h={4}
                  w="auto"
                />
              </Text>
              <Text
                as="span"
                fontSize="sm"
                color={{ base: 'greyscale.500', md: 'greyscale.700' }}
              >
                {journeyUtils.flightDateFormatted(flight.localDepartureDate)}
              </Text>
              {getShowProtectedTag() && (
                <Tag
                  size="xs"
                  backgroundColor="success.200"
                  color="success.500"
                  mx={5}
                >
                  <TagLabel
                    fontWeight="extrabold"
                    textTransform="uppercase"
                    data-testid="protected-flight"
                  >
                    {t('common.protected')}
                  </TagLabel>
                </Tag>
              )}
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={{ base: 'start', md: 'flex-end' }}
          >
            {flight.flightStatus ? (
              <FlightStatusBadge flightStatus={flight.flightStatus} />
            ) : null}
          </Flex>
        </Flex>
        <Flex
          borderBottomRightRadius="lg"
          borderBottomLeftRadius="lg"
          bgColor="greyscale.100"
          py={3}
          px={{ base: 4, md: 6 }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {flight.departureAirport ? (
            <AirportCard
              airport={flight.departureAirport}
              flight={flight}
              flightType="departure"
            />
          ) : null}
          <Separator />
          <AirportCard
            airport={flight.arrivalAirport}
            flight={flight}
            flightType="arrival"
          />
        </Flex>
      </Box>
      {isConnectingFlight ? (
        <Flex
          alignItems="center"
          justifyContent={{ base: 'flex-start', md: 'center' }}
          my={5}
          ms={{ base: 5, md: 0 }}
        >
          <ConnectingFlightLeft color="primary.500" h={5} w="auto" />
          <AirplaneRight color="primary.500" h={4} w="auto" />
          <ConnectingFlightRight color="primary.500" h={5} w="auto" />
          <Text as="span" ms={4} fontSize="sm" color="greyscale.500">
            {t('common.connecting_flight')}
          </Text>
        </Flex>
      ) : null}
    </>
  );
};

export default FlightCard;
