import React from 'react';
import { generatePath } from 'react-router';
import { Navigate } from 'react-router-dom';
import track from 'react-tracking';

import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { ClaimProgressView } from 'components/Claim/ClaimProgressView';
import useFeatureFlags from 'hooks/api/useFeatureFlags';

const ClaimProgressPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();
  const { data: featureFlags } = useFeatureFlags();

  usePageNavigationHeader({
    title: null,
    backPath: generatePath(PAGE_PATHS.CLAIMS_PAGE),
  });

  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();

  if (isUserProfileLoading) {
    return <Loader dataTestId="loader" />;
  }

  if (!userProfile || !featureFlags?.new_claim_details) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return <ClaimProgressView />;
};

export default track({ page_type: 'Claim.Progress' })(ClaimProgressPage);
