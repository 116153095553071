import React from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import track from 'react-tracking';

import FlightsView from 'components/Journey/FlightsView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import useJourney from 'hooks/api/journey/useJourney';

interface Params extends Record<string, string | undefined> {
  id: string;
}

const FlightsPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { id } = useParams<Params>();

  if (!id) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  const {
    data: journeyData,
    isLoading,
    isError,
  } = useJourney({
    id: parseInt(id),
  });

  if (isLoading) {
    return <PageLoader />;
  } else if (isError || !journeyData) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }
  return <FlightsView journey={journeyData} />;
};

export default track({ page_type: 'Journey.Flights' })(FlightsPage);
