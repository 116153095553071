import React from 'react';
import Slider from 'react-slick';

import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';

import BlankTile from 'components/Journeys/JourneysList/BlankTile';
import JourneyTile from 'components/Journeys/JourneysList/JourneyTile';

import styles from '../Slider.module.scss';
import { Journey } from 'models/journey/Journey';

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: false,
  dotsClass: [styles.dots],
};

interface IComponent {
  showBlankTile: boolean;
  journeys: Journey[];
  numberOfGridColumns: number;
}

const JourneyTiles: React.FC<IComponent> = ({
  numberOfGridColumns,
  showBlankTile,
  journeys,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <Box
        mt={6}
        sx={{
          '.slick-list': {
            margin: '0 -16px 0 0',
            padding: '0 !important',
            overflow: 'unset',
          },
          '.slick-track': { display: 'flex' },
          '.slick-slide': {
            display: 'flex',
            height: 'unset',
            '> div': { width: '100%' },
          },
        }}
        data-testid="latest-journeys-container-slider"
      >
        <Slider {...sliderSettings}>
          {journeys.map((journey) => (
            <Box pe={4} key={journey.id}>
              <JourneyTile
                journey={journey}
                className={styles.slide}
                boxShadow="0px 1px 2px 0px #1E293614"
              />
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }

  return (
    <SimpleGrid
      mt={4}
      spacing={4}
      width="100%"
      columns={numberOfGridColumns}
      data-testid="latest-journeys-container"
    >
      {journeys.map((journey) => (
        <JourneyTile journey={journey} key={journey.id} />
      ))}
      {showBlankTile ? <BlankTile /> : null}
    </SimpleGrid>
  );
};

export default JourneyTiles;
