import { createVerificationSession, VerificationSchema } from '@airhelp/plus';
import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';

const useCreateVerificationSession = () => {
  const useCreateVerificationSessionMutation = useMutation({
    mutationFn: (params: VerificationSchema) =>
      createVerificationSession({
        client: ahplusClient,
        params,
      }),
  });

  return useCreateVerificationSessionMutation;
};

export default useCreateVerificationSession;
