import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import appleWalletIcon from 'assets/images/lounges/apple_wallet_icon@2x.png';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  addToAppleWalletUrl: string;
}

const AddToAppleWalletButton: React.FC<IComponent> = ({
  addToAppleWalletUrl,
}) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  return (
    <Button
      as="a"
      href={addToAppleWalletUrl}
      width={{ base: '100%', md: 'auto' }}
      onClick={() => trackCtaClick('add to apple wallet', 'lounges pass page')}
      fontSize="sm"
      bgColor="greyscale.900"
      whiteSpace="nowrap"
      leftIcon={
        <Image src={appleWalletIcon} alt="Apple Wallet icon" boxSize={6} />
      }
    >
      {t('lounges.passes.add_to_apple_wallet')}
    </Button>
  );
};

export default AddToAppleWalletButton;
