import React from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import track from 'react-tracking';

import { Center } from '@chakra-ui/react';

import AirportLoungesView from 'components/LoungeFinder/AirportLoungesView';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import { usePageTracking } from 'utils/tracking';
import useLounges from 'hooks/api/useLounges';

interface PageParams extends Record<string, string | undefined> {
  airport_code: string;
}

const AirportPage = () => {
  usePageTracking();

  const { airport_code: airportCode } = useParams<PageParams>();

  if (airportCode) {
    const { data: lounges, isLoading } = useLounges({
      airportCode,
    });

    return isLoading ? (
      <Center>
        <Loader />
      </Center>
    ) : (
      <AirportLoungesView lounges={lounges || []} />
    );
  }

  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.LOUNGE_FINDER_PAGE),
      }}
    />
  );
};

export default track({ page_type: 'LoungeFinder.Airport' })(AirportPage);
