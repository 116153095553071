import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Slider from 'react-slick';

import {
  VStack,
  useBreakpointValue,
  Box,
  Button,
  type BoxProps,
} from '@chakra-ui/react';

import flightsCheckedFormal from 'assets/images/flights-checked-formal@2x.png';
import ClaimCard from 'components/Claims/ClaimsList/ClaimCard';
import ClaimListItem from 'components/Claims/ClaimsList/ClaimListItem';
import Header from 'components/Claims/ClaimsList/Header';
import styles from 'components/Journeys/JourneysLatest/Slider.module.scss';
import { PAGE_PATHS } from 'config/routes';
import LargeBlankTile from 'elements/LargeBlankTile';
import { type ClaimEnquiry } from 'models/Claim';
import { useTrackEvent } from 'utils/tracking/hooks';
import { getWebappNewClaimUrl } from 'utils/sites';
import { LinkTarget } from 'types/utils';

type IComponent = {
  claimEnquiries: ClaimEnquiry[];
} & BoxProps;

const NUM_OF_CLAIMS_TO_SHOW = 3;

const ClaimsLatest: React.FC<IComponent> = ({ claimEnquiries, ...props }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const onAddClaimClick = () => {
    trackCtaClick('add claim', 'home page');
  };

  const onSeeAllClick = () => {
    trackCtaClick('see all claims', 'home page');
    navigate(PAGE_PATHS.CLAIMS_PAGE);
  };

  const onCompleteClaimClick = () => {
    trackCtaClick('complete claim', 'home page');
  };

  const incompleteClaims = claimEnquiries.filter(
    (claimEnquiry) => !claimEnquiry.claimId,
  );
  const submittedClaims = claimEnquiries.filter(
    (claimEnquiry) => claimEnquiry.claimId,
  );
  const latestClaims = [...incompleteClaims, ...submittedClaims].slice(
    0,
    NUM_OF_CLAIMS_TO_SHOW,
  );

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    dotsClass: [styles.dots],
  };

  return claimEnquiries.length > 0 ? (
    <Box
      overflow={{ base: 'hidden', md: 'unset' }}
      mx={{ base: -4, md: 'unset' }}
      px={{ base: 4, md: 'unset' }}
      {...props}
    >
      <VStack
        width="100%"
        spacing={4}
        p={{ base: 4, md: 0 }}
        bg={{ base: 'greyscale.100', md: 'transparent' }}
        borderRadius="lg"
      >
        <Header
          heading={t('claims.your_compensation_claims')}
          onSeeAllClick={onSeeAllClick}
          onAddClaimClick={onAddClaimClick}
        />
        {isMobile ? (
          <>
            <Box
              sx={{
                '.slick-list': { margin: '0 -16px 0 0', overflow: 'unset' },
                '.slick-track': { display: 'flex' },
                '.slick-slide': {
                  display: 'flex',
                  height: 'unset',
                  '> div': { width: '100%' },
                },
              }}
              data-testid="latest-claims-container-slider"
              width="100%"
              height="100%"
            >
              <Slider {...sliderSettings}>
                {latestClaims.map((claimEnquiry) => (
                  <Box key={claimEnquiry.id} pe={4} height="100%">
                    <ClaimCard
                      claimEnquiry={claimEnquiry}
                      className={styles.slide}
                      height="100%"
                      onCompleteClaimClick={onCompleteClaimClick}
                      sourceParam="dashboard"
                    />
                  </Box>
                ))}
              </Slider>
            </Box>

            <Button
              size="md"
              onClick={onSeeAllClick}
              variant="secondary"
              width="100%"
              data-testid="btn-see-all-claims"
              mt={7}
            >
              {t('common.see_all')}
            </Button>
          </>
        ) : (
          latestClaims.map((claimEnquiry) => (
            <ClaimListItem
              key={claimEnquiry.id}
              claimEnquiry={claimEnquiry}
              sourceParam="dashboard"
            />
          ))
        )}
      </VStack>
    </Box>
  ) : (
    <VStack
      width="100%"
      spacing={4}
      bgColor={{ base: 'greyscale.100', md: 'transparent' }}
      p={{ base: 4, md: 0 }}
      borderRadius="lg"
      {...props}
    >
      <Header
        heading={t('claims.your_compensation_claims')}
        onAddClaimClick={onAddClaimClick}
      />
      <LargeBlankTile
        image={flightsCheckedFormal}
        header={t('claims.no_claims_yet')}
        description={t('claims.start_a_new_claim')}
        buttonLabel={t('claims.add_claim')}
        onClick={onAddClaimClick}
        externalLink={getWebappNewClaimUrl()}
        externalLinkTarget={LinkTarget.SELF}
        data-testid="large-blank-tile-claims"
        border={{ base: '1px solid', md: 'none' }}
        borderColor="greyscale.400"
      />
    </VStack>
  );
};

export default ClaimsLatest;
