import React, { forwardRef } from 'react';

import { Button, useTab, UseTabProps } from '@chakra-ui/react';

interface CustomTabProps extends UseTabProps {
  children: React.ReactNode;
  key: string;
  dataTestId: string;
  onClick: () => void;
}

const CustomTab = forwardRef<HTMLButtonElement, CustomTabProps>(
  (props, ref) => {
    const { children, dataTestId, ...rest } = props;
    const tabProps = useTab({ ...rest, ref });
    const isSelected = Boolean(tabProps['aria-selected']);

    return (
      <Button
        size="s"
        textColor={isSelected ? 'primary.600' : 'greyscale.600'}
        backgroundColor={isSelected ? 'primary.200' : 'greyscale.100'}
        borderRadius={50}
        me={2}
        py={2}
        px={5}
        fontWeight={isSelected ? 'bold' : 'normal'}
        _hover={
          isSelected
            ? { textColor: 'primary.700', backgroundColor: 'primary.300' }
            : { textColor: 'greyscale.600', backgroundColor: 'primary.100' }
        }
        data-testid={dataTestId}
        {...tabProps}
      >
        {children}
      </Button>
    );
  },
);

CustomTab.displayName = 'CustomTab';

export default CustomTab;
