import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router';

import { VStack, SimpleGrid } from '@chakra-ui/react';

import tripsRoute from 'assets/images/trips-route@2x.png';
import { PAGE_PATHS } from 'config/routes';
import { type TabState } from 'contexts/TabsContext/TabsContext';
import LargeBlankTile from 'elements/LargeBlankTile';
import LargeUpsellTile from 'elements/LargeUpsellTile';
import { useTrackEvent } from 'utils/tracking/hooks';

import BlankTile from './BlankTile';
import Heading from './Heading';
import JourneyTile from './JourneyTile';
import { Journey } from 'models/journey/Journey';
import { UserPolicy } from 'models/userPolicy';

interface IComponent {
  journeys: Journey[];
  activeTab: TabState;
  userPolicy: UserPolicy;
}

export const JourneysList: React.FC<IComponent> = ({
  journeys,
  activeTab,
  userPolicy,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const hasJourneys = journeys && journeys.length > 0;
  const upsellEnabled = userPolicy.dashboard.upsellEnabled;
  const canAddTrip = userPolicy.dashboard?.canAddTrip;

  const onAddTripButtonClick = () => {
    trackCtaClick('add trip button', 'journeys page');
    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
      state: {
        from: 'journeys',
      },
    });
  };
  const isActiveTabSelected = activeTab === 'Active';
  const isPastTabSelected = activeTab === 'Past';

  return (
    <VStack spacing={8} width="100%">
      <Heading userPolicy={userPolicy} />
      {hasJourneys ? (
        <SimpleGrid
          spacing={4}
          width="100%"
          columns={{ base: 1, md: 2, xl: 3 }}
        >
          {journeys.map((journey) => (
            <JourneyTile journey={journey} key={journey.id} />
          ))}
          {canAddTrip && isActiveTabSelected ? (
            <BlankTile actionSource="journeys" />
          ) : null}
        </SimpleGrid>
      ) : canAddTrip ? (
        <LargeBlankTile
          image={tripsRoute}
          header={t('journeys.protect_future_trip')}
          description={t('journeys.enjoy_peace_of_mind')}
          buttonLabel={t('dashboard.add_trip')}
          onClick={onAddTripButtonClick}
          data-testid="large-blank-tile-trips"
        />
      ) : upsellEnabled ? (
        <LargeUpsellTile
          onClick={() => trackCtaClick('see more plans', 'journeys page')}
        />
      ) : (
        <LargeBlankTile
          image={tripsRoute}
          header={
            isActiveTabSelected
              ? t('journeys.no_active_trips')
              : isPastTabSelected
                ? t('journeys.no_past_trips')
                : ''
          }
        />
      )}
    </VStack>
  );
};

export default JourneysList;
