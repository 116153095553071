import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { fetchTrip, FetchTripResponse } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

const useTrip = ({
  options,
  uuid,
}: {
  options?: ApiQueryOptions<FetchTripResponse>;
  uuid: string;
}) => {
  return useProtectedQuery({
    queryKey: ['trip', { uuid }],
    queryFn: () => fetchTrip({ client: ahplusClient, params: { uuid } }),
    ...options,
  });
};

export default useTrip;
