import {
  getUA,
  isConsole,
  isMobile,
  isTablet,
  mobileModel,
  osName,
} from 'react-device-detect';

import { BREAKPOINTS } from 'config/sassVariables';
import type UserProfile from 'models/userProfile/UserProfile';

export interface UserTrackingData {
  userTier?: string;
}

const pagePath = () => window.location.pathname;
const pageTitle = () => document.title;
const pageUrl = () => window.location.href;
const viewportWidth = () => window.innerWidth;
const viewportHeight = () => window.innerHeight;

const deviceModel = () => {
  return mobileModel !== 'none' ? mobileModel : osName || undefined;
};

const deviceType = () => {
  switch (true) {
    case isConsole:
      return 'Console';
    case isMobile:
      return 'Mobile';
    case isTablet:
      return 'Tablet';
    default:
      return 'Desktop';
  }
};

const webTheme = () => {
  switch (true) {
    case viewportWidth() <= BREAKPOINTS.PHONE:
      return 'Phone';
    case viewportWidth() < BREAKPOINTS.TABLET:
      return 'Tablet';
    default:
      return 'Desktop';
  }
};

const userTier = (userProfile: UserProfile) => {
  const policy = userProfile.policy;
  const isMembershipComplete = policy.membership?.isComplete;

  if (!isMembershipComplete && policy.trip) {
    return 'b2b';
  }

  return policy.membership?.tier || 'smart';
};

const getPageViewedData = (appLocale: string) => ({
  name: 'pageViewed', // required by current react-tracking implementation
  event: 'pageViewed', // will be required by GTM

  deviceModel: deviceModel(),
  deviceType: deviceType(),
  pageLanguage: appLocale,
  pagePath: pagePath(),
  pageReferrer: document.referrer,
  pageTitle: pageTitle(),
  pageUrl: pageUrl(),
  userAgent: getUA,
  viewportHeight: viewportHeight(),
  viewportWidth: viewportWidth(),
  webTheme: webTheme(),
});

const getUserData = (userProfile?: UserProfile): UserTrackingData => {
  if (!userProfile) {
    return {};
  }
  return {
    userTier: userTier(userProfile),
  };
};

export { getPageViewedData, getUserData };
