import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from '@chakra-ui/react';

import AirBundleTagStatus from 'components/Journey/AirBundle/AirBundleTagStatus';
import getAirPayoutStatusBadge from 'components/Journey/AirPayout/getAirPayoutStatusBadge';
import { policyUrl } from 'utils/insurancePolicy';

import AirPayoutAction from './AirPayoutAction';
import Header from './Header';
import { PolicyButton } from '../HeaderContent';
import { getTooltipStatus } from './getTooltipStatus';
import { NotificationDot } from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';
import AirBundleThead, { airBundleTableStyle } from '../AirBundleThead';
import { Journey } from 'models/journey/Journey';
import { AirPayoutJourney } from 'models/journey/AirPayoutJourney';

const AirPayoutInsuranceView = ({
  journey,
  airPayoutJourneys,
}: {
  journey: Journey;
  airPayoutJourneys: AirPayoutJourney[];
}) => {
  const { t } = useTranslation();

  const { mainAirPayoutJourney } = journey;

  const ipidVersion = mainAirPayoutJourney?.ipidVersion;
  const linkToFile = policyUrl(true, ipidVersion);

  return (
    <Box
      p={{ base: 4, md: 8 }}
      background="greyscale.100"
      borderRadius="lg"
      width="100%"
    >
      <Header airPayoutJourney={airPayoutJourneys[0]} linkToFile={linkToFile} />
      <Box
        p={{ base: 0, md: 4 }}
        background={{ base: 'greyscale.100', md: 'greyscale.300' }}
        borderRadius="lg"
      >
        {/* DesktopView only */}
        <AirBundleThead />
        <Flex flexDirection="column">
          {airPayoutJourneys.map((airPayoutJourney, index) => {
            const { status, showNotification } = airPayoutJourney;
            const lastElement = index + 1 === airPayoutJourneys.length;
            const boxTag = getAirPayoutStatusBadge(status);

            return (
              <React.Fragment key={airPayoutJourney.id}>
                {/* DesktopView */}
                <Flex
                  alignItems="center"
                  display={{ base: 'none', md: 'grid' }}
                  gridTemplateColumns="repeat(3, 1fr)"
                  py={4}
                  pb={lastElement ? 0 : 4}
                  borderBottom={lastElement ? 'none' : '1px solid'}
                  borderColor="greyscale.400"
                >
                  <Flex alignItems="center">
                    {showNotification ? <NotificationDot /> : null}
                    <Text
                      {...airBundleTableStyle.td}
                      ms={showNotification ? 2 : 0}
                    >
                      {airPayoutJourney.name}
                    </Text>
                  </Flex>
                  <AirBundleTagStatus
                    statusBox={boxTag}
                    tooltipLabel={getTooltipStatus(airPayoutJourney)}
                    dataTestId="status-tag-airpayout"
                  />
                  <Text
                    {...airBundleTableStyle.td}
                    color="greyscale.500"
                    fontWeight="normal"
                    textAlign="right"
                  >
                    <AirPayoutAction
                      airPayoutJourney={airPayoutJourney}
                      journeyId={journey.id}
                    />
                  </Text>
                </Flex>
                {/* MobileView */}
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  flexDirection="column"
                  background="greyscale.300"
                  borderRadius="lg"
                  p={4}
                  mb={2}
                >
                  <Flex alignItems="center" pb={3}>
                    {showNotification ? <NotificationDot /> : null}
                    <Text
                      {...airBundleTableStyle.td}
                      ms={showNotification ? 2 : 0}
                    >
                      {airPayoutJourney.name}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" pb={4}>
                    <Text {...airBundleTableStyle.th}>
                      {t('common.status')}
                    </Text>
                    <AirBundleTagStatus
                      statusBox={boxTag}
                      tooltipLabel={getTooltipStatus(airPayoutJourney)}
                      dataTestId="status-tag-airpayout"
                    />
                  </Flex>
                  <Flex justifyContent="space-between" pb={4}>
                    <Text {...airBundleTableStyle.th}>
                      {t('common.action')}
                    </Text>
                    <Text
                      {...airBundleTableStyle.td}
                      color="greyscale.500"
                      fontWeight="normal"
                    >
                      <AirPayoutAction
                        airPayoutJourney={airPayoutJourney}
                        journeyId={journey.id}
                      />
                    </Text>
                  </Flex>
                </Flex>
              </React.Fragment>
            );
          })}
        </Flex>
      </Box>
      <Box display={{ base: 'block', md: 'none' }} mt={2}>
        <PolicyButton linkToFile={linkToFile} />
      </Box>
    </Box>
  );
};

export default AirPayoutInsuranceView;
