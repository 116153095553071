import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { InfoOutline, PlusMath } from '@airhelp/icons';
import {
  Box,
  Heading as ChakraHeading,
  Button,
  useBreakpointValue,
  IconButton,
  Flex,
  Tooltip,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';
import { pluralVersion } from 'utils/translations';
import { UserPolicy } from 'models/userPolicy';

interface IComponent {
  canAddJourney: boolean;
  onSeeAllButtonClick: () => void;
  showSeeAllButton: boolean;
  userPolicy: UserPolicy;
}

const Heading = ({
  canAddJourney,
  onSeeAllButtonClick,
  showSeeAllButton,
  userPolicy,
}: IComponent) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const required24hDepartureTime =
    userPolicy.benefits?.required24hDepartureTime;
  const departureThresholdHours = userPolicy.benefits
    ? userPolicy.benefits.departureThresholdHours
    : 48;

  const onAddTripButtonClick = () => {
    trackCtaClick('add trip button', 'home page');

    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
      state: {
        from: 'dashboard',
      },
    });
  };

  return (
    <HStack width="100%">
      <Box width={{ base: '70%', md: '50%' }}>
        <ChakraHeading variant="h5" display="inline">
          {t('journeys.your_trips')}
        </ChakraHeading>
        {canAddJourney ? (
          <Tooltip
            label={t(
              pluralVersion(
                required24hDepartureTime
                  ? 'journeys.your_trips_tip'
                  : 'journeys.your_trips_benefits_tip',
                departureThresholdHours,
              ),
              { count: departureThresholdHours },
            )}
            isOpen={isOpen}
            hasArrow
            arrowPadding={6}
            bgColor="greyscale.800"
            borderRadius="md"
            p={4}
          >
            <InfoOutline
              color="primary.500"
              ms={1}
              boxSize={4}
              h="auto"
              verticalAlign="text-top"
              cursor="pointer"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              onClick={onToggle}
            />
          </Tooltip>
        ) : null}
      </Box>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        width={{ base: '30%', md: '50%' }}
      >
        {isMobile ? (
          canAddJourney && (
            <IconButton
              icon={<PlusMath fontSize="md" />}
              onClick={onAddTripButtonClick}
              isRound
              size="sm"
              aria-label="add trip"
              data-testid="btn-add-trip"
            />
          )
        ) : (
          <HStack spacing={4}>
            {showSeeAllButton ? (
              <Button
                size="md"
                onClick={onSeeAllButtonClick}
                variant="secondary"
                data-testid="btn-see-all-trips"
              >
                {t('common.see_all')}
              </Button>
            ) : null}
            {canAddJourney ? (
              <Button
                size="md"
                onClick={onAddTripButtonClick}
                data-testid="btn-add-trip"
              >
                <PlusMath me={2} fontSize="lg" />
                {t('journeys.add_trip')}
              </Button>
            ) : null}
          </HStack>
        )}
      </Flex>
    </HStack>
  );
};

export default Heading;
