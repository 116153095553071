import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { policyUrl } from 'utils/insurancePolicy';

import Header from './Header';
import { PolicyButton } from '../HeaderContent';
import AirBundleTagStatus from 'components/Journey/AirBundle/AirBundleTagStatus';
import { useTranslation } from 'react-i18next';
import useGetAirLuggageStatusBadge from 'components/Journey/AirLuggage/getAirLuggageStatusBadge';
import AirLuggageAction from './AirLuggageAction';
import { getTooltipStatus } from './getTooltipStatus';
import { NotificationDot } from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';
import { LuggageSolid } from '@airhelp/icons';
import AirBundleThead, { airBundleTableStyle } from '../AirBundleThead';
import { Journey } from 'models/journey/Journey';
import { AirLuggageJourney } from 'models/journey/AirLuggageJourney';

const AirLuggageInsuranceView = ({
  journey,
  airLuggageJourneys,
}: {
  journey: Journey;
  airLuggageJourneys: AirLuggageJourney[];
}) => {
  const { t } = useTranslation();
  const { mainAirLuggageJourney } = journey;

  const ipidVersion = mainAirLuggageJourney?.ipidVersion;
  const linkToFile = policyUrl(false, ipidVersion);

  return (
    <Box
      p={{ base: 4, md: 8 }}
      background="greyscale.100"
      borderRadius="lg"
      width="100%"
    >
      <Header
        airLuggageJourney={airLuggageJourneys[0]}
        linkToFile={linkToFile}
      />
      <Box
        p={{ base: 0, md: 4 }}
        background={{ base: 'greyscale.100', md: 'greyscale.300' }}
        borderRadius="lg"
      >
        {/* DesktopView only */}
        <AirBundleThead />
        <Flex flexDirection="column">
          {airLuggageJourneys.map((airLuggageJourney, index) => {
            const { status, showNotification } = airLuggageJourney;
            const lastElement = index + 1 === airLuggageJourneys.length;
            const boxTag = useGetAirLuggageStatusBadge(status);

            return (
              <React.Fragment key={airLuggageJourney.id}>
                {/* DesktopView */}
                <Flex
                  alignItems="center"
                  display={{ base: 'none', md: 'grid' }}
                  gridTemplateColumns="repeat(3, 1fr)"
                  py={4}
                  pb={lastElement ? 0 : 4}
                  borderBottom={lastElement ? 'none' : '1px solid'}
                  borderColor="greyscale.400"
                >
                  <Flex alignItems="center">
                    {showNotification ? <NotificationDot /> : null}
                    <Text
                      {...airBundleTableStyle.td}
                      ms={showNotification ? 2 : 0}
                    >
                      {airLuggageJourney.name}
                    </Text>
                    <LuggageSolid
                      width={4}
                      height={4}
                      color="greyscale.600"
                      ms={2}
                      me={1}
                    />
                    <Text fontSize="xs" color="greyscale.600">
                      {airLuggageJourney.maxBags}
                    </Text>
                  </Flex>
                  <AirBundleTagStatus
                    statusBox={boxTag}
                    tooltipLabel={getTooltipStatus(airLuggageJourney)}
                    dataTestId="status-tag-airluggage"
                  />
                  <Text
                    {...airBundleTableStyle.td}
                    color="greyscale.500"
                    fontWeight="normal"
                    textAlign="right"
                  >
                    <AirLuggageAction airLuggageJourney={airLuggageJourney} />
                  </Text>
                </Flex>
                {/* MobileView */}
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  flexDirection="column"
                  background="greyscale.300"
                  borderRadius="lg"
                  p={4}
                  mb={2}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    pb={3}
                  >
                    <Flex alignItems="center">
                      {showNotification ? <NotificationDot /> : null}
                      <Text
                        {...airBundleTableStyle.td}
                        ms={showNotification ? 2 : 0}
                      >
                        {airLuggageJourney.name}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontSize="xs" color="greyscale.600">
                        {airLuggageJourney.maxBags}
                      </Text>
                      <LuggageSolid
                        width={4}
                        height={4}
                        color="greyscale.600"
                        ms={2}
                      />
                    </Flex>
                  </Flex>
                  <Flex justifyContent="space-between" pb={4}>
                    <Text {...airBundleTableStyle.th}>
                      {t('common.status')}
                    </Text>
                    <AirBundleTagStatus
                      statusBox={boxTag}
                      tooltipLabel={getTooltipStatus(airLuggageJourney)}
                      dataTestId="status-tag-airluggage"
                    />
                  </Flex>
                  <Flex justifyContent="space-between" pb={4}>
                    <Text {...airBundleTableStyle.th}>
                      {t('common.action')}
                    </Text>
                    <Text
                      {...airBundleTableStyle.td}
                      color="greyscale.500"
                      fontWeight="normal"
                    >
                      <AirLuggageAction airLuggageJourney={airLuggageJourney} />
                    </Text>
                  </Flex>
                </Flex>
              </React.Fragment>
            );
          })}
        </Flex>
      </Box>
      <Box display={{ base: 'block', md: 'none' }} mt={2}>
        <PolicyButton linkToFile={linkToFile} />
      </Box>
    </Box>
  );
};

export default AirLuggageInsuranceView;
