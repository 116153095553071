import { useMutation } from '@tanstack/react-query';
import { ahplusClient } from 'api/clients/clients';
import { cancelMembership, CancelMembershipParams } from '@airhelp/plus';

const useCancelMembership = () => {
  const useCancelMembershipMutation = useMutation({
    mutationFn: (params: CancelMembershipParams) =>
      cancelMembership({
        client: ahplusClient,
        params,
      }),
  });

  return useCancelMembershipMutation;
};

export default useCancelMembership;
