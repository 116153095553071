import {
  CheckDeletableReason,
  JourneyCheckDeletable as IJourneyCheckDeletable,
} from '@airhelp/plus';
import { plainToClass, Type } from 'class-transformer';

export class JourneyCheckDeletable implements IJourneyCheckDeletable {
  deletable: boolean;
  loungesIncluded: boolean;
  blockedBy: CheckDeletableReason[];

  @Type(() => Boolean)
  get blockedByB2b(): boolean {
    return this.blockedBy.includes(CheckDeletableReason.B2B);
  }

  @Type(() => Boolean)
  get blockedByLounges(): boolean {
    return this.blockedBy.includes(CheckDeletableReason.LOUNGES);
  }

  @Type(() => Boolean)
  get blockedByAirPayout(): boolean {
    return this.blockedBy.includes(CheckDeletableReason.AIR_PAYOUT);
  }

  @Type(() => Boolean)
  get blockedByAirLuggage(): boolean {
    return this.blockedBy.includes(CheckDeletableReason.AIR_LUGGAGE);
  }
}

export function transformToJourneyCheckDeletableClass(
  journeyCheckDeletable: IJourneyCheckDeletable,
): JourneyCheckDeletable {
  return plainToClass(JourneyCheckDeletable, journeyCheckDeletable);
}
