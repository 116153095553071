import { fetchUpgradeOffers, FetchUpgradeOffersResponse } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';

const useFetchUpgradeOffers = ({
  currency = 'EUR',
  options,
}: {
  currency: string;
  options?: ApiQueryOptions<FetchUpgradeOffersResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['upgrade_offer', currency],
    queryFn: () =>
      fetchUpgradeOffers({ client: ahplusClient, params: { currency } }),
    ...options,
  });
};

export default useFetchUpgradeOffers;
