import { Flex, Text, Avatar } from '@chakra-ui/react';

interface AirportProps {
  airport: string;
  airportCountryCode: string;
  direction?: 'row' | 'row-reverse';
}

export function Airport({
  airport,
  airportCountryCode,
  direction = 'row',
}: AirportProps) {
  return (
    <Flex alignItems="center" gap="6px" direction={direction}>
      {/* TODO: use rounded flags from DS */}
      <Avatar name={airportCountryCode} w={5} h={5} size="small" />
      <Text
        color="greyscale.500"
        fontSize="11px"
        fontWeight="bold"
        textTransform="uppercase"
      >
        {airport}
      </Text>
    </Flex>
  );
}
