import {
  AirPayoutJourney as IFetchAirPayoutJourneysResponse,
  AirPayoutStatus,
  AirPayoutDisruption,
  AirPayoutCoverage,
  AirPayoutEligibleReason,
} from '@airhelp/plus';
import { Expose, plainToClass } from 'class-transformer';

export class AirPayoutJourney implements IFetchAirPayoutJourneysResponse {
  id: number;
  status: AirPayoutStatus;
  journeyId: number;
  payoutAllowed?: boolean;
  payoutUpdateAllowed?: boolean;
  name: string;
  payoutRequested: boolean;
  payoutAmount: number;
  disruptionType: AirPayoutDisruption;
  coverageType: AirPayoutCoverage;
  ipidVersion?: string;
  payoutEligibleReason: AirPayoutEligibleReason | null;

  @Expose()
  get outboundOnly(): boolean {
    return this.coverageType === AirPayoutCoverage.OUTBOUND_ONLY;
  }

  @Expose()
  get cancelationOnly(): boolean {
    return this.disruptionType === AirPayoutDisruption.CANCELATION_ONLY;
  }

  @Expose()
  get showNotification(): boolean {
    return (
      this.status === AirPayoutStatus.ELIGIBLE ||
      this.status === AirPayoutStatus.PAYOUT_FAILED ||
      this.status === AirPayoutStatus.PAYOUT_RETURNED
    );
  }

  @Expose()
  get airPayoutEligibleCanceled(): boolean {
    return this.payoutEligibleReason === AirPayoutEligibleReason.CANCELED;
  }
}

export function transformToAirPayoutJourneyClass(
  airPayoutData: IFetchAirPayoutJourneysResponse,
): AirPayoutJourney {
  return plainToClass(AirPayoutJourney, airPayoutData);
}
