import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { RoundedButton } from 'components/RoundedButton/RoundedButton';
import { AltArrowBack, Support } from '@airhelp/icons';

interface HeaderProps {
  title: string;
}

export function Header({ title }: HeaderProps) {
  const showSupportIcon = useBreakpointValue({
    base: true,
    xl: false,
  });

  return (
    <Flex
      placeItems="flex-start"
      flexDirection={{
        base: 'row',
        xl: 'column',
      }}
      justifyContent={{
        base: 'space-between',
        xl: 'normal',
      }}
      pb={{
        base: 0,
        md: 10,
        lg: 8,
      }}
    >
      <RoundedButton Icon={AltArrowBack} label="back" navigateTo="/claims" />
      <Text
        fontSize={{
          base: 'lg',
          xl: '24px',
        }}
        fontWeight={{
          base: 'medium',
          xl: 'bold',
        }}
        color="primary.900"
        mt={{
          base: 0,
          xl: 10,
        }}
      >
        {title}
      </Text>
      {showSupportIcon ? (
        <RoundedButton Icon={Support} label="back" navigateTo="/claims" />
      ) : null}
    </Flex>
  );
}
