import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import MyAccountForm from 'components/MyAccountView/MyAccountForm';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useEffectOnce from 'hooks/useEffectOnce';
import { useTrackEvent } from 'utils/tracking/hooks';
import { Loader } from 'elements';

const CollectUserDetails = () => {
  const { t } = useTranslation();
  const { trackOnboardingFunnel, trackCtaClick } = useTrackEvent();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: userProfile,
    refetch: refetchUserProfile,
    isLoading: isLoadingUserProfile,
  } = useFetchMyProfile();

  useEffectOnce(() => {
    onOpen();
    trackOnboardingFunnel('user_details');
  });

  if (isLoadingUserProfile) {
    return <Loader />;
  }

  const hasLoungeAccess = userProfile?.policy.benefits?.loungesIncluded;

  const onSubmit = () => {
    trackCtaClick('confirm', 'user details modal');
  };

  const onCloseModal = () => {
    refetchUserProfile();
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        background="greyscale.100"
        minH={{ base: '785px', lg: '845px' }}
        data-testid="collect-user-details-modal"
      >
        <ModalBody pt={{ base: 6, lg: 16 }} pb={{ base: 6, lg: 6 }}>
          <Heading variant="h1" mb={6}>
            {t('my_account.details.your_details')}
          </Heading>
          <Text fontSize="lg" mb={6} data-testid="user-details-description">
            {hasLoungeAccess
              ? t('my_account.details.description_complete')
              : t('my_account.details.description_essential')}
          </Text>
          {userProfile ? (
            <MyAccountForm
              actionLabel={t('common.confirm')}
              onDataSaved={onCloseModal}
              onSubmit={onSubmit}
              userProfile={userProfile}
            />
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CollectUserDetails;
