import React from 'react';
import { useTranslation } from 'react-i18next';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box, Flex } from '@chakra-ui/react';

import AirBundleModal from 'components/Journey/AirBundle/AirBundleModal';

import TripItineraries from './TripItineraries';
import useFetchAirBundleSubscription from 'hooks/api/userProfiles/useFetchAirBundleSubscription';
import { Journey } from 'models/journey/Journey';

interface IComponent {
  journey: Journey;
  showAirPayoutSubscriptionModal: boolean;
}

const TripCard: React.FC<IComponent> = ({
  journey,
  showAirPayoutSubscriptionModal,
}) => {
  const { t } = useTranslation();
  const { data: airBundleSubscriptionData } = useFetchAirBundleSubscription({
    enabled: showAirPayoutSubscriptionModal,
    gcTime: 0,
  });

  const itineraries = journey.itineraries;

  const hasItineraries = itineraries && itineraries.length > 0;

  const showAirBundleSubscriptionModal =
    airBundleSubscriptionData && showAirPayoutSubscriptionModal;

  return (
    <>
      {showAirBundleSubscriptionModal ? (
        <AirBundleModal
          journey={journey}
          airBundleSubscriptionData={airBundleSubscriptionData}
        />
      ) : null}
      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection={{ base: 'column', md: 'row' }}
        borderRadius="xl"
        backgroundColor="greyscale.100"
        p={{ base: 4, md: 8 }}
      >
        <Box width="100%">
          <TripItineraries itineraries={itineraries} journey={journey} />
          {hasItineraries ? (
            <InfoBox
              isChat
              backgroundColor="greyscale.300"
              icon={<Info color="primary.500" display="inline-flex" mb={1} />}
              variant="secondary"
              border="none"
            >
              {t('flights_list.showing_local_times')}
            </InfoBox>
          ) : null}
        </Box>
      </Flex>
    </>
  );
};

export default TripCard;
