import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AltArrowBack, AltArrowForward } from '@airhelp/icons';
import { Flex, IconButton, Box } from '@chakra-ui/react';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';

import TopBanners from 'components/Layouts/Default/TopBanners';
import { isRtl } from 'utils/textDirection/isRtl';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

const MobileHeader: React.FC = () => {
  const { userProfile } = useMyProfile();
  const { locale } = useLocale();

  const { mobileHeaderConfig } = useLayout();
  const navigate = useNavigate();

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showPaymentManagementBanner = Boolean(
    userProfile?.policy.membership?.showPaymentManagementBanner,
  );

  const handleBackButtonClick = () => {
    const backRoute = mobileHeaderConfig?.backPath || PAGE_PATHS.HOME_PAGE;

    navigate(backRoute);
  };

  const showTopBanner =
    showTopBannerEssentialUpgrade || showPaymentManagementBanner;

  const getMarginTop = () => {
    const largeMargin = { base: '96px', md: '128px' };
    const mediumMargin = { base: '48px', md: '64px' };

    const bannerCount =
      Number(showTopBannerEssentialUpgrade) +
      Number(showPaymentManagementBanner);

    switch (bannerCount) {
      case 2:
        return largeMargin;
      case 1:
        return mediumMargin;
      default:
        return 'unset';
    }
  };

  return (
    <>
      {showTopBanner ? (
        <Box position="relative">
          <TopBanners
            showTopBannerEssentialUpgrade={showTopBannerEssentialUpgrade}
            showPaymentManagementBanner={showPaymentManagementBanner}
          />
        </Box>
      ) : null}
      <Flex
        as="header"
        minHeight="56px"
        position="relative"
        width="100%"
        px={4}
        zIndex="10"
        backgroundColor="greyscale.300"
        mt={getMarginTop()}
      >
        <Flex width="20%" alignItems="center" justifyContent="flex-start">
          <IconButton
            aria-label="back"
            icon={
              isRtl(locale) ? (
                <AltArrowForward fontSize="xl" />
              ) : (
                <AltArrowBack fontSize="xl" />
              )
            }
            onClick={handleBackButtonClick}
            size="md"
            color="primary.500"
            borderRadius="50%"
            bg="greyscale.100"
            _active={{ bgColor: 'primary.200', boxShadow: 'none' }}
            _hover={{ bgColor: 'primary.200' }}
            data-testid="back-to"
          />
        </Flex>
        <Flex
          width="60%"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          color="primary.900"
          fontWeight="semibold"
        >
          {mobileHeaderConfig?.title}
        </Flex>
        {mobileHeaderConfig?.actionSpace ? (
          <Flex
            width="20%"
            alignItems="center"
            justifyContent="flex-end"
            me={3}
          >
            {mobileHeaderConfig?.actionSpace}
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};

export default MobileHeader;
