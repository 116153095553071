import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import { Plus } from '@airhelp/icons';
import { AirportOption } from '@airhelp/react';

import { Airport } from '@airhelp/webapp';
import {
  Box,
  Button,
  Flex,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { flatMap, compact, uniq } from 'lodash';

import ContentBox from 'components/Flights/New/ContentBox';
import { EditedJourneyContext } from 'contexts';
import AirportAutocomplete from 'elements/AirportAutocomplete';
import { EditableTypes } from 'reducers/editableJourney';
import { useTrackEvent } from 'utils/tracking/hooks';
import { EditableFlight } from '@airhelp/plus';

interface IComponent {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

type ConnectionValueType = 'yes' | 'no';

const ConnectingFlightsView: React.FC<IComponent> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const { trackEvent } = useTracking();
  const { t, i18n } = useTranslation();
  const { editedItinerary, itineraryIndex, dispatch } =
    useContext(EditedJourneyContext);
  const { flights } = editedItinerary;

  const { trackCtaClick } = useTrackEvent();

  const getCityName = (airport) =>
    Object.keys(airport.cityTranslations).length
      ? airport.cityTranslations[i18n.language]
      : airport.city;

  const headerInterpolation = {
    departureAirport: getCityName(flights[0].departureAirport),
    arrivalAirport: getCityName(flights[flights.length - 1].arrivalAirport),
  };

  const [hasConnectingFlightsValue, setHasConnectingFlightsValue] =
    useState<ConnectionValueType>('no');
  const hasConnectingFlights = hasConnectingFlightsValue === 'yes';
  const connectingFlights = flights.slice(1);

  const disabledAirportsIata = uniq(
    compact(
      flatMap(flights, (flight) => [
        flight.arrivalAirport?.iata,
        flight.departureAirport?.iata,
      ]),
    ),
  );

  useEffect(() => {
    setHasConnectingFlightsValue(flights.length > 1 ? 'yes' : 'no');
  }, [flights]);

  const onSubmit = () => {
    dispatch({
      type: EditableTypes.CLEAR_EMPTY_FLIGHTS,
      itineraryIndex,
    });
    onNextStep();
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const formHasErrors = Object.keys(errors).length > 0;

  const handleCheckboxChange = (newValue: ConnectionValueType) => {
    const hasConnectingFlights = hasConnectingFlightsValue === 'yes';

    hasConnectingFlights
      ? dispatch({
          type: EditableTypes.REMOVE_ALL_CONNECTING_FLIGHTS,
          itineraryIndex,
        })
      : dispatch({ type: EditableTypes.ADD_FLIGHT, itineraryIndex });

    setHasConnectingFlightsValue(newValue);

    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'selected',
      eventLabel: 'ahplus_dashboard_connecting_flight_set',
      hasConnectingFlights: hasConnectingFlights ? 'no' : 'yes',
    });

    trackCtaClick(
      newValue === 'yes' ? 'yes, connecting flight' : 'no, direct flight',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - connecting flights`,
    );
  };

  const addConnectingFlight = () => {
    dispatch({
      type: EditableTypes.ADD_FLIGHT,
      itineraryIndex,
    });
    trackCtaClick(
      'add another connection',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'}  - connecting flights`,
    );
  };

  const handleUpdateConnectingFlight = (
    airport: AirportOption | null,
    flight: EditableFlight,
  ) => {
    dispatch({
      type: EditableTypes.UPDATE_CONNECTING_FLIGHT_AIRPORT,
      itineraryIndex,
      payload: {
        ...flight,
        departureAirport: airport?.value || null,
        departureAirportCode: airport?.value.iata || '',
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6}>
        {/* checkbox for connecting flights */}
        <ContentBox
          header={t(
            `funnels.add_new_flight.connecting_flights_step.header`,
            headerInterpolation,
          )}
        >
          <Flex flexDirection="column" maxWidth={{ base: '100%', lg: '410px' }}>
            <RadioGroup
              onChange={handleCheckboxChange}
              value={hasConnectingFlightsValue}
            >
              <Stack direction="column" spacing={0}>
                <Radio
                  width="100%"
                  value="no"
                  name="connecting-flights-no"
                  variant="top"
                  data-testid="connecting-flights-no"
                >
                  {t('funnels.add_new_flight.connecting_flights_step.no')}
                </Radio>

                <Radio
                  width="100%"
                  value="yes"
                  name="connecting-flights-yes"
                  variant="bottom"
                  data-testid="connecting-flights-yes"
                >
                  {t('funnels.add_new_flight.connecting_flights_step.yes')}
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </ContentBox>

        {/* inputs for connecting flights */}
        {hasConnectingFlights ? (
          <ContentBox
            header={t(
              'funnels.add_new_flight.connecting_flights_step.connection_header',
            )}
          >
            <Box maxWidth={{ base: '100%', md: '410px' }}>
              {connectingFlights.map((flight) => (
                <AirportAutocomplete
                  key={flight.id}
                  defaultAirport={flight.departureAirport as Airport}
                  handleChange={(airport) =>
                    handleUpdateConnectingFlight(airport, flight)
                  }
                  label={t(
                    'funnels.add_new_flight.connecting_flights_step.connection_input_label',
                  )}
                  formControlStyles={{
                    mb: 4,
                  }}
                  disabledIatas={disabledAirportsIata}
                  dataTestId="connection-airport"
                />
              ))}
              <Link
                mt={6}
                onClick={addConnectingFlight}
                display="flex"
                alignItems="center"
              >
                <Plus h={5} w="auto" />
                <Text fontSize="md" ps={2} display="inline">
                  {t(
                    'funnels.add_new_flight.connecting_flights_step.add_connection',
                  )}
                </Text>
              </Link>
            </Box>
          </ContentBox>
        ) : null}
      </VStack>

      {/* form actions */}
      <Stack
        direction={{ base: 'column', md: 'row-reverse' }}
        mt={6}
        justifyContent="end"
        spacing={5}
      >
        <Button
          type="submit"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          isDisabled={formHasErrors}
          data-testid="form-action-continue"
        >
          {t('common.continue')}
        </Button>
        <Button
          variant="secondary"
          minWidth={{ base: '100%', md: '80px' }}
          size="s"
          onClick={onPreviousStep}
          data-testid="form-action-back"
        >
          {t('funnels.back')}
        </Button>
      </Stack>
    </form>
  );
};

export default ConnectingFlightsView;
