import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowLeft, ArrowRight } from '@airhelp/icons';
import { Button, Divider, HStack } from '@chakra-ui/react';
import { isRtl } from 'utils/textDirection/isRtl';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

interface IComponent {
  url: string;
  label: string;
  onBackClick?: () => void;
}

const BackButton: React.FC<IComponent> = ({ url, label, onBackClick }) => {
  const navigate = useNavigate();
  const { locale } = useLocale();

  const goBack = () => {
    if (onBackClick) {
      onBackClick();
    }
    navigate(url);
  };

  return (
    <>
      <HStack
        w={{ base: '100%', lg: '970px' }}
        mx={{ base: 'auto' }}
        mb={4}
        px={{ base: 4, lg: 0 }}
      >
        <Button
          variant="link"
          display="inline-flex"
          alignItems="center"
          fontSize="sm"
          onClick={goBack}
          leftIcon={
            isRtl(locale) ? (
              <ArrowRight w="5" height="5" />
            ) : (
              <ArrowLeft w="5" height="5" />
            )
          }
        >
          {label}
        </Button>
      </HStack>
      <Divider
        borderColor="greyscale.400"
        mx={4}
        mb={4}
        w="auto"
        display={{ base: 'block', sm: 'none' }}
      />
    </>
  );
};

export default BackButton;
