import React, { useMemo } from 'react';
import DrawerComponent from '../../common/DrawerComponent';
import { BillingManagementAddress, type UpdateCreditCard } from '@airhelp/plus';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import config from 'config';
import useStripeObject from 'hooks/api/payments/useStripeObject';
import StripePaymentForm from './StripePaymentForm';
import { Loader } from 'elements';
import { theme } from 'components/StripeCheckoutWidget/theme';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  onClose: () => void;
  isOpen: boolean;
  creditCardData: UpdateCreditCard;
  billingAddress?: BillingManagementAddress | null;
  isUpdate?: boolean;
}

const CreditCardDrawer: React.FC<IComponent> = ({
  onClose,
  isOpen,
  creditCardData,
  billingAddress,
  isUpdate,
}) => {
  const { locale } = useLocale();
  const { trackPageInteractions } = useTrackEvent();
  const { clientSecret } = creditCardData;

  const { stripePublishableKey, stripeAccountId } = config;

  const stripeObject = useStripeObject({
    publishableKey: stripePublishableKey,
    accountId: stripeAccountId,
  });

  const options = useMemo(() => {
    return {
      clientSecret,
      appearance: theme,
      locale,
      loader: 'never',
    } as StripeElementsOptions;
  }, [clientSecret, locale]);

  const onPaymentSubmit = async () => {
    trackPageInteractions(
      isUpdate ? 'payment method updated' : 'payment method added',
      `ahp payment management`,
    );
  };

  return (
    <DrawerComponent
      content={
        !stripeObject ? (
          <Loader />
        ) : (
          <Elements options={options} stripe={stripeObject}>
            <StripePaymentForm
              clientSecret={clientSecret}
              billingAddress={billingAddress}
              onPaymentSubmit={onPaymentSubmit}
              isUpdate={isUpdate}
            />
          </Elements>
        )
      }
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default CreditCardDrawer;
