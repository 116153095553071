import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Plus, DeleteBin, Edit } from '@airhelp/icons';
import { Input } from '@airhelp/react';
import { Box, Button, Collapse, Flex, Text } from '@chakra-ui/react';

import {
  nameMaxLength,
  nameMinLength,
  removeExtraSpace,
} from 'utils/validations';

import FormIcon from './FormIcon';
import { EditableFellowPassenger } from '@airhelp/plus';

const PassengerForm = ({
  handleCancel,
  form,
  onSubmit,
  isOpen,
  handleEdit,
  handleDelete,
  handleAdd,
  disabledAdd,
  passenger,
}: {
  form: UseFormReturn<EditableFellowPassenger>;
  handleCancel: () => void;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleDelete?: (passenger: EditableFellowPassenger) => void;
  onSubmit: (data: EditableFellowPassenger) => void;
  isOpen: boolean;
  disabledAdd?: boolean;
  passenger?: EditableFellowPassenger;
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, formState } = form;
  const { isValid, errors } = formState;

  const key = passenger
    ? `${passenger.firstName}-${passenger.lastName}`
    : 'new';
  return (
    <Box
      px={4}
      py={3}
      mt={2}
      border="1px"
      borderStyle="solid"
      borderColor="greyscale.400"
      borderRadius="md"
      data-testid={`fellow-passenger-${key}`}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          fontSize={passenger ? 'sm' : 'md'}
          fontWeight={passenger ? 'normal' : 'medium'}
          color={disabledAdd ? 'primary.300' : 'primary.900'}
        >
          {passenger
            ? `${passenger.firstName} ${passenger.lastName}`
            : t('funnels.add_new_flight.follow_passengers.add_a_passenger')}
        </Text>
        <Flex>
          {handleAdd ? (
            <FormIcon
              iconName="Plus"
              Icon={Plus}
              onClick={handleAdd}
              isDisabled={Boolean(disabledAdd)}
              dataTestId="add-fellow-passenger"
            />
          ) : null}
          {handleEdit && passenger ? (
            <FormIcon
              iconName="Edit"
              Icon={Edit}
              onClick={() => handleEdit()}
              dataTestId={`edit-fellow-passenger-${passenger.firstName}-${passenger.lastName}`}
            />
          ) : null}
          {handleDelete && passenger ? (
            <FormIcon
              iconName="DeleteBin"
              Icon={DeleteBin}
              onClick={() => handleDelete(passenger)}
              dataTestId={`delete-fellow-passenger-${passenger.firstName}-${passenger.lastName}`}
            />
          ) : null}
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box minH="106px" w="100%" mt={6}>
          <Controller
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                isInvalid={Boolean(errors.firstName)}
                errorMessage={errors.firstName?.message || ''}
                size="lg"
                label={t('user.first_name')}
                value={value}
                type="text"
                placeholder={t('user.first_name')}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  onChange(removeExtraSpace(value));
                }}
                data-testid="first-name"
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('errors.please_add_first_name'),
              },
              minLength: {
                value: nameMinLength,
                message: t('errors.too_short', {
                  min: nameMinLength,
                  attribute: t('user.first_name'),
                }),
              },
              maxLength: {
                value: nameMaxLength,
                message: t('errors.too_long', {
                  max: nameMaxLength,
                  attribute: t('user.first_name'),
                }),
              },
            }}
            name="firstName"
            control={control}
            defaultValue={passenger?.firstName || ''}
          />
        </Box>

        <Box minH="106px" w="100%" mb={2}>
          <Controller
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                isInvalid={Boolean(errors.lastName)}
                errorMessage={errors.lastName?.message || ''}
                size="lg"
                label={t('user.last_name')}
                value={value}
                type="text"
                placeholder={t('user.last_name')}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  onChange(removeExtraSpace(value));
                }}
                data-testid="last-name"
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('errors.please_add_last_name'),
              },
              minLength: {
                value: nameMinLength,
                message: t('errors.too_short', {
                  min: nameMinLength,
                  attribute: t('user.last_name'),
                }),
              },
              maxLength: {
                value: nameMaxLength,
                message: t('errors.too_long', {
                  max: nameMaxLength,
                  attribute: t('user.last_name'),
                }),
              },
            }}
            name="lastName"
            control={control}
            defaultValue={passenger?.lastName || ''}
          />
        </Box>
        <Flex
          justifyContent="flex-end"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
        >
          <Button
            variant="secondary"
            onClick={handleCancel}
            minWidth={{ base: '100%', md: '80px' }}
            size="s"
            me={{ base: 0, md: 3 }}
            mt={{ base: 4, md: 0 }}
            data-testid="cancel-action-fellow-passenger"
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            minWidth={{ base: '100%', md: '68px' }}
            size="s"
            data-testid="save-fellow-passenger"
          >
            {t('common.save')}
          </Button>
        </Flex>
      </Collapse>
    </Box>
  );
};

export default PassengerForm;
