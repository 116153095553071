import React from 'react';
import { useTranslation } from 'react-i18next';

import { shadowsLg } from '@airhelp/design-tokens/dist/js/design-tokens';
import {
  USAToday,
  TheWashingtonPost,
  WSJ as Wsj,
  APRA as Apra,
} from '@airhelp/react';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getYear } from 'date-fns';

import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { type TranslationKey } from 'types/i18n';
import { getWebsitePageUrl } from 'utils/sites';

interface ILink {
  tKey: TranslationKey;
  key: string;
  url: string;
}

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const copyrightDate = getYear(new Date());

  const featuredImages = [
    { Image: TheWashingtonPost, key: 'the_washington_post' },
    { Image: USAToday, key: 'usa_today' },
    { Image: Wsj, key: 'wsj' },
  ];

  const links: ILink[] = [
    {
      tKey: 'common.air_passenger_rights',
      key: 'air_passenger_rights',
      url: getWebsitePageUrl('airPassengerRights', locale),
    },
    {
      tKey: 'common.help',
      key: 'help',
      url: getWebsitePageUrl('help', locale),
    },
    {
      tKey: 'common.terms_and_conditions',
      key: 'terms_and_conditions',
      url: getWebsitePageUrl('termsPath', locale),
    },
    {
      tKey: 'common.privacy_policy',
      key: 'privacy_policy',
      url: getWebsitePageUrl('privacyPath', locale),
    },
  ];

  return (
    <Box
      px={{ base: 4, sm: 16 }}
      pt={{ base: 20, md: 12 }}
      pb={{ base: 6, sm: 12 }}
      data-testid="layout-footer"
      backgroundColor="greyscale.300"
      position="relative"
    >
      <Center
        position="absolute"
        top={{ base: -20, md: -12 }}
        left={4}
        right={4}
      >
        <Flex
          px={{ base: 10, md: 12 }}
          py={{ base: 4, md: 0 }}
          h={{ base: 40, md: 24 }}
          backgroundColor="greyscale.100"
          borderRadius="xl"
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          boxShadow={shadowsLg}
          width={{
            base: 'calc(100vw - 32px)',
            md: 'calc(100vw - 128px)',
            lg: 'unset',
          }}
        >
          <Text
            flexShrink={0}
            textTransform="uppercase"
            fontWeight="700"
            letterSpacing="1px"
            fontSize="sm"
          >
            {t('lounge_finder.footer.en_airhelp_featured_in')}
          </Text>
          <Spacer me={8} display={{ base: 'none', md: 'block' }} />
          <HStack
            flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
            flexBasis="100%"
            spacing={{ base: 0, md: 2, lg: 8 }}
          >
            {featuredImages.map(({ Image, key }) => (
              <Image
                h={{ base: 6, lg: 7 }}
                my={{ base: 4, md: 2 }}
                w="auto"
                color="greyscale.500"
                key={key}
              />
            ))}
          </HStack>
        </Flex>
      </Center>

      <Flex
        mt={12}
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          spacing={{ base: 4, sm: 4 }}
          alignItems={{ base: 'flex-start', sm: 'center' }}
          maxW={{ base: '100%', md: '80%' }}
        >
          <Apra h={{ base: 8, sm: 10 }} w="auto" />
          <Text
            fontSize="sm"
            color="greyscale.600"
            maxW={{ base: 'none', md: '505px' }}
          >
            {t('lounge_finder.footer.en_airhelp_is_part_of')}
          </Text>
        </Stack>
      </Flex>

      <HStack
        my={12}
        spacing={6}
        display={{ base: 'none', sm: 'inline-flex' }}
        flexWrap="wrap"
        justifyContent="space-around"
      >
        {links.map((link) => {
          return (
            <Link
              href={link.url}
              key={link.key}
              color="greyscale.600"
              _hover={{ color: 'greyscale.800' }}
              fontSize="sm"
            >
              {t(link.tKey, { lng: 'en' })}
            </Link>
          );
        })}
      </HStack>

      <Divider borderColor="greyscale.400" my={8} />

      <Box mt={8} textAlign="right">
        <Text fontSize="sm">
          {t('common.en_copyright', { date: copyrightDate })}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
