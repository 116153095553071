import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Info, Ok } from '@airhelp/icons';
import { Button, useBreakpointValue, useToast } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';
import { Journey } from 'models/journey/Journey';
import useDeleteJourney from 'hooks/api/journey/useDeleteJourney';

interface IComponent {
  journey: Journey;
  handleCloseDelete: () => void;
}

const DeleteAction: React.FC<IComponent> = ({ journey, handleCloseDelete }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const deleteJourney = useDeleteJourney();
  const { isPending: isLoading, isError } = deleteJourney;

  const closeModalHandler = () => {
    handleCloseDelete();
  };

  const handleDeleteJourney = () => {
    deleteJourney.mutate(
      { id: journey.id },
      {
        onSuccess: () => {
          toast({
            title: (
              <span data-testid="trip-deleted">
                {t('journeys.delete_modal.successful_deleted')}
              </span>
            ),
            status: 'success',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
          closeModalHandler();
          trackCtaClick('delete trip', 'delete trip question');
          navigate(PAGE_PATHS.HOME_PAGE);
        },
        onError: () => {
          toast({
            title: (
              <span data-testid="trip-not-deleted">
                {t('journeys.delete_modal.unsuccessful_deleted')}
              </span>
            ),
            status: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
        },
      },
    );
  };

  return (
    <Button
      onClick={handleDeleteJourney}
      type="submit"
      variant="danger"
      width="100%"
      size="s"
      isLoading={isLoading}
      isDisabled={isLoading || isError}
      data-testid="delete-button-delete-journey"
    >
      {t('journeys.delete_modal.delete_trip')}
    </Button>
  );
};

export default DeleteAction;
