import { useEffect } from 'react';
import { VStack } from '@chakra-ui/react';
import cookie from 'react-cookies';
import Questions from './Questions';
import PlanUpsell from './PlanUpsell';
import PlanComparison from './PlanComparison';
import NewBenefits from './NewBenefits';
import PartnerDeals from './PartnerDeals';
import { usePreferencesStore } from 'stores/preferences';
import useEffectOnce from 'hooks/useEffectOnce';
import { createCookieOptions } from 'utils/cookies';
import { AHP_ITEM_LIST_NAME_COOKIE } from 'config/cookies';
import {
  EcommerceEvent,
  TrackEcommerceEvent,
} from 'utils/tracking/props/ga4/types';
import { useTracking } from 'react-tracking';
import useFetchUpgradeOffers from 'hooks/api/offers/useFetchUpgradeOffers';

export default function SpecialOfferView() {
  const currency = usePreferencesStore((state) => state.currency);
  const { trackEvent } = useTracking<TrackEcommerceEvent>();
  const { data, isLoading } = useFetchUpgradeOffers({ currency });

  const upgradeOffer = data?.upgradeOffer;

  useEffectOnce(() => {
    cookie.save(
      AHP_ITEM_LIST_NAME_COOKIE,
      'essential_smart_upgrade',
      createCookieOptions(),
    );
  });

  useEffect(() => {
    if (!isLoading && Boolean(upgradeOffer)) {
      trackEvent({
        event: EcommerceEvent.VIEW_ITEM,
        ecommerce: {
          currency: upgradeOffer?.code || currency,
          item_list_name: 'essential_smart_upgrade',
          items: [
            {
              item_id: 'airhelp_smart_annually',
              item_name: 'AirHelpPlus Membership',
              discount: upgradeOffer?.discountValue ?? 0,
              index: 1,
              item_brand: 'AirHelp',
              item_category: '1YearMembership',
              item_category2: 'Subscription',
              item_list_name: 'essential_smart_upgrade',
              item_variant: 'smart',
              price: upgradeOffer?.productPrice,
              quantity: 1,
            },
          ],
        },
      });
    }
  }, [data, isLoading]);

  return (
    <VStack
      bg="greyscale.100"
      px={{ base: 4, md: 10 }}
      py={{ base: 8, md: 10 }}
      borderRadius="lg"
      alignItems="center"
      justifyContent="center"
      spacing={{ base: 10 }}
      data-testid="special-offer-view"
    >
      <PlanComparison
        currency={currency}
        ecommerceItem={{
          item_id: 'airhelp_smart_annually',
          item_name: 'AirHelpPlus Membership',
          discount: upgradeOffer?.discountValue ?? 0,
          index: 1,
          item_brand: 'AirHelp',
          item_category: '1YearMembership',
          item_category2: 'Subscription',
          item_variant: 'smart',
          item_list_name: 'essential_smart_upgrade',
          price: upgradeOffer?.productPrice,
          quantity: 1,
        }}
      />
      <NewBenefits />
      <PartnerDeals />
      <Questions />
      <PlanUpsell />
    </VStack>
  );
}
