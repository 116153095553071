import React from 'react';
import { generatePath, useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import track from 'react-tracking';

import { PAGE_PATHS } from 'config/routes';

import { usePageTracking } from 'utils/tracking';

const ClaimPage: React.FC = () => {
  usePageTracking();
  const { id } = useParams();

  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.CLAIM.PROGRESS_PAGE, {
          id: Number(id),
        }),
      }}
    />
  );
};

export default track({ page_type: 'Claim.Index' })(ClaimPage);
