import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { fetchJourney, FetchJourneyResponse } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { transformToFetchJourneyClass } from 'models/journey/Journey';

const useJourney = ({
  id,
  options,
}: {
  id: number;
  options?: ApiQueryOptions<FetchJourneyResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['journey', { id }],
    queryFn: () => fetchJourney({ client: ahplusClient, params: { id } }),
    select: (journey) => transformToFetchJourneyClass(journey),
    ...options,
  });
};

export default useJourney;
