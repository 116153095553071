import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router-dom';

import {
  ReturnFlight,
  AltArrowExpand,
  AltArrowCollapse,
  Lounge,
  AirplaneRight,
} from '@airhelp/icons';
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  VStack,
  Accordion,
  useDisclosure,
  Tag,
  TagLabel,
  HStack,
} from '@chakra-ui/react';

import config from 'config';
import { PAGE_PATHS } from 'config/routes';
import { journeyUtils } from 'utils';
import chakraStyles from 'utils/styles/chakra';

import AirportName from './AirportName';
import FlightCard from './FlightCard';
import { AirPayoutCoverage, Itinerary } from '@airhelp/plus';
import { Journey } from 'models/journey/Journey';

const { flightStatuses } = config;

const TripItineraries = ({
  itineraries,
  journey,
}: {
  itineraries: Itinerary[];
  journey: Journey;
}) => {
  const { t } = useTranslation();

  const hasAirPayout = journey.hasAirPayout;
  const hasAirLuggage = journey.hasAirLuggage;

  const outboundOnly =
    journey.mainAirPayoutJourney?.coverageType ===
    AirPayoutCoverage.OUTBOUND_ONLY;

  return (
    <>
      {itineraries.map((itinerary, itineraryIndex) => {
        const { isOpen, onToggle } = useDisclosure();

        const isReturnTrip = itineraryIndex === 1 && itineraries.length == 2;
        const flights = itinerary.flights;

        const firstFlight = flights[0];
        const lastFlightIndex = flights.length - 1;
        const lastFlight = flights[lastFlightIndex];

        const departureAirport = firstFlight.departureAirport;
        const arrivalAirport = lastFlight.arrivalAirport;

        const pastFlight = firstFlight.localDepartureDate < new Date();

        const claimNowUrl = journeyUtils.createClaimUrl(itinerary);

        const disruptedFlight = flights.find(
          (flight) =>
            (!pastFlight &&
              flight.flightStatus?.departureStatus ===
                flightStatuses.canceled) ||
            (!flight.flightStatus?.gmtActualDepartureAt &&
              flight.flightStatus?.departureStatus === flightStatuses.delayed),
        );

        const hasDisruptedFlight = Boolean(disruptedFlight);

        const getShowProtectedTag = () => {
          return (
            hasAirLuggage ||
            (hasAirPayout && !(outboundOnly && itineraryIndex !== 0))
          );
        };

        return (
          <Box key={`itinerary-${itinerary.id}`} mb={4}>
            {isReturnTrip ? (
              <Flex
                fontSize="lg"
                fontWeight="medium"
                color="primary.900"
                alignItems="center"
                mb={4}
              >
                <ReturnFlight width={5} height={5} me={2} color="primary.500" />
                {t('common.return')}
              </Flex>
            ) : null}
            {/* itinerary header */}
            <VStack spacing={6} alignItems="flex-start">
              {/* itinerary destination */}
              <Flex
                width="100%"
                justifyContent="space-between"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Flex
                  alignItems="center"
                  justifyContent={{ base: 'space-between', md: 'flex-start' }}
                  color="primary.900"
                  width="100%"
                >
                  <AirportName
                    airportCity={journeyUtils.cityTranslations(
                      departureAirport,
                    )}
                    airportCode={departureAirport ? departureAirport.iata : ''}
                    side="left"
                  />
                  <AirplaneRight
                    color="greyscale.600"
                    w={{ base: 6, md: 5 }}
                    h="auto"
                    mx={{ base: 4, md: 8 }}
                  />
                  <AirportName
                    airportCity={journeyUtils.cityTranslations(arrivalAirport)}
                    airportCode={arrivalAirport ? arrivalAirport.iata : ''}
                    side="right"
                  />
                </Flex>
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  justifyContent={{ base: 'flex-start', md: 'flex-end' }}
                  my={{ base: 4, md: 0 }}
                  width="100%"
                  display={
                    journey.loungeActive || itinerary.claimAllowed
                      ? 'flex'
                      : 'none'
                  }
                >
                  <HStack spacing={4}>
                    {journey.loungeActive ? (
                      <Button
                        as={ReactLink}
                        to={{ pathname: generatePath(PAGE_PATHS.LOUNGES_PAGE) }}
                        variant="secondary"
                        size="xs"
                        width={{ base: '100%', md: 'auto' }}
                        textTransform="capitalize"
                        height="40px"
                        data-testid="itinerary-lounge-access"
                      >
                        <Lounge me={1} />
                        {t('common.lounge_access')}
                      </Button>
                    ) : null}
                    {itinerary.claimAllowed ? (
                      <Button
                        as={Link}
                        href={claimNowUrl}
                        size="xs"
                        width={{ base: '100%', md: 'auto' }}
                        height="40px"
                        data-testid="itinerary-start-claim"
                        {...chakraStyles.buttonLinkStyles}
                      >
                        {t('flights_list.start_claim')}
                      </Button>
                    ) : null}
                  </HStack>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt={3}
                  display={{ base: 'flex', md: 'none' }}
                >
                  <Flex>
                    <AirplaneRight color="greyscale.600" w={5} h="auto" />
                    <Flex
                      fontWeight="bold"
                      fontSize="x-small"
                      color="greyscale.600"
                      borderRadius="full"
                      flex="none"
                      minWidth={5}
                      h={5}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {flights.length}
                    </Flex>
                  </Flex>

                  {getShowProtectedTag() && (
                    <Tag
                      size="xs"
                      backgroundColor="success.200"
                      color="success.500"
                    >
                      <TagLabel
                        fontWeight="extrabold"
                        textTransform="uppercase"
                        data-testid="protected-itinerary"
                      >
                        {t('common.protected')}
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
              </Flex>
            </VStack>
            {/* flights list */}
            <Box mt={6}>
              <Accordion
                variant="link"
                onClick={onToggle}
                size="s"
                mb="2"
                width={{ base: '100%', md: 'auto' }}
                justifyContent="space-between"
                fontWeight="500"
                color="primary.900"
                _focus={{ boxShadow: 'none' }}
                display={{ base: 'flex', md: 'none' }}
                data-testid="show-more-journey-details"
              >
                <Flex alignItems="center">
                  <Box ms={2}>{t('flights_list.more_details')}</Box>
                  {hasDisruptedFlight ? (
                    <Box ms={2}>
                      <Text
                        backgroundColor="danger.500"
                        borderRadius="full"
                        color="greyscale.100"
                        whiteSpace="nowrap"
                        fontSize="x-small"
                        fontWeight="semibold"
                        lineHeight="base"
                        textTransform={{ base: 'uppercase', md: 'initial' }}
                        px={2}
                        py={1}
                        letterSpacing="0.5px"
                        data-testid="disrupted-badge"
                      >
                        {t('common.disrupted')}
                      </Text>
                    </Box>
                  ) : null}
                </Flex>
                {isOpen ? (
                  <AltArrowCollapse
                    width={5}
                    height={5}
                    me={3}
                    color="primary.500"
                  />
                ) : (
                  <AltArrowExpand
                    width={5}
                    height={5}
                    me={3}
                    color="primary.500"
                  />
                )}
              </Accordion>
              <Box display={{ base: isOpen ? 'block' : 'none', md: 'block' }}>
                {flights.map((flight, index) => {
                  const isConnectingFlight = index != lastFlightIndex;
                  return (
                    <FlightCard
                      flight={flight}
                      isConnectingFlight={isConnectingFlight}
                      getShowProtectedTag={getShowProtectedTag}
                      key={`flight-${flight.id}`}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default TripItineraries;
