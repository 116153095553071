import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import track from 'react-tracking';

import BenefitsView from 'components/MyAccountView/BenefitsView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';
import useJourneys from 'hooks/api/journey/useJourneys';

const BenefitsPage: React.FC = () => {
  usePageTracking();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  const { isLoading: isLoadingUserProfile, data: userProfile } =
    useFetchMyProfile();
  const { isLoading: isJourneysLoading, data: journeysData } = useJourneys();
  const journeys = journeysData || [];

  if (isLoadingUserProfile || isJourneysLoading || !userProfile) {
    return <Loader dataTestId="loader" />;
  }

  return <BenefitsView userProfile={userProfile} journeys={journeys} />;
};

export default track({ page_type: 'MyAccount.Benefits' })(BenefitsPage);
