import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useNavigate } from 'react-router-dom';
import track from 'react-tracking';

import AirLuggagePayoutView from 'components/Journey/AirLuggage/PayoutPaymentView';
import AirPayoutPayoutView from 'components/Journey/AirPayout/PayoutPaymentView';
import { PAGE_PATHS } from 'config/routes';
import {
  useLayout,
  usePageNavigationHeader,
} from 'contexts/LayoutContext/LayoutContext';
import { PageLoader } from 'elements';
import ErrorPageView from 'elements/ErrorView';
import { type ComponentPageProps } from 'funnels/AirBundleFunnel';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectEligible } from 'utils/airBundle/airBundle';
import { getVeriffUrl } from 'utils/veriff';
import { InsuranceType } from '@airhelp/plus';
import { pascalCase } from 'utils/pascalCase';
import useJourney from 'hooks/api/journey/useJourney';
import useFetchAirLuggageJourney from 'hooks/api/airBundle/useFetchAirLuggageJourney';
import useFetchAirPayoutJourney from 'hooks/api/airBundle/useFetchAirPayoutJourney';

const PayoutPage: React.FC<ComponentPageProps> = ({
  journeyId,
  insuranceType,
}) => {
  const { setActiveStep } = useLayout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading: isLoadingUserProfile } = useFetchMyProfile();
  const { data: journeyData, isLoading: isLoadingJourney } = useJourney({
    id: parseInt(journeyId),
  });

  const [allowedPayout, setAllowedPayout] = useState<boolean | undefined>();
  const [airRequestId, setAirRequestId] = useState<number | undefined>();

  const id = parseInt(journeyId);

  usePageNavigationHeader({
    title: t('navigation.your_trip'),
    backPath: generatePath(PAGE_PATHS.JOURNEY_PAGE, { id }),
  });

  useEffectOnce(() => {
    setActiveStep(1);
  });

  const queryData = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return useFetchAirLuggageJourney({
          journeyId: parseInt(journeyId),
        });
      case InsuranceType.AIR_PAYOUT:
      default:
        return useFetchAirPayoutJourney({
          journeyId: parseInt(journeyId),
        });
    }
  })();

  useEffect(() => {
    if (allowedPayout === undefined && queryData.data) {
      setAllowedPayout(selectEligible(queryData.data).length > 0);
    }
  }, [allowedPayout, setAllowedPayout, queryData.data]);

  if (queryData.isError) {
    return <ErrorPageView goBack={() => navigate(-1)} />;
  }

  if (
    allowedPayout == undefined ||
    !queryData.data ||
    isLoadingUserProfile ||
    !journeyData ||
    isLoadingJourney
  ) {
    return <PageLoader />;
  }

  if (!allowedPayout) {
    const pathname = generatePath(PAGE_PATHS.JOURNEY_PAGE, { id });
    return <Navigate to={{ pathname }} />;
  }

  if (airRequestId) {
    // cleanup after flag removed
    const url = getVeriffUrl(id, insuranceType, airRequestId);
    if (url) {
      const veriffPath = PAGE_PATHS.JOURNEY[insuranceType.toUpperCase()]
        .VERIFF_PAGE as string;
      return (
        <Navigate
          to={{
            pathname: generatePath(veriffPath, { id }),
            search: `airRequestId=${airRequestId}`,
          }}
        />
      );
    }
    const confirmationPath = PAGE_PATHS.JOURNEY[insuranceType.toUpperCase()]
      .PAYOUT_CONFIRMATION_PAGE as string;
    return (
      <Navigate to={{ pathname: generatePath(confirmationPath, { id }) }} />
    );
  }

  const PayoutView = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return AirLuggagePayoutView;
      case InsuranceType.AIR_PAYOUT:
      default:
        return AirPayoutPayoutView;
    }
  })();
  return (
    <PayoutView
      journeyListData={selectEligible(queryData.data)}
      journeyData={journeyData}
      onTriggerSuccessful={(airRequestId?: number) => {
        setAirRequestId(airRequestId);
      }}
    />
  );
};

export default track({
  page_type: (props: ComponentPageProps) =>
    `Journey.${pascalCase(props.insuranceType)}.Payout`,
})(PayoutPage);
