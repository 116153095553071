import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { fetchLounges, FetchLoungesResponse } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

const useLounges = ({
  options,
  airportCode,
}: {
  options?: ApiQueryOptions<FetchLoungesResponse>;
  airportCode: string;
}) => {
  return useProtectedQuery({
    queryKey: ['lounges', { airportCode }],
    queryFn: () =>
      fetchLounges({ client: ahplusClient, params: { airportCode } }),
    ...options,
  });
};

export default useLounges;
