import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import ChangePassword from '../ChangePassword';
import MyAccountForm from '../MyAccountForm';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { Loader } from 'elements';
import { generatePath, Navigate } from 'react-router';
import { PAGE_PATHS } from 'config/routes';

const DetailsView = () => {
  const { t } = useTranslation();

  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useFetchMyProfile();

  if (isLoadingUserProfile) {
    return <Loader />;
  }

  if (!userProfile) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return (
    <Box>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        bg="greyscale.100"
        p={{ base: 4, md: 6 }}
        borderRadius="lg"
        rowGap={{ base: 6, md: 8 }}
      >
        <Box w={{ base: '100%', lg: '33%' }}>
          <Heading variant="h5">{t('my_account.details.your_details')}</Heading>
          <Text fontSize="m" color="primary.700">
            {t('my_account.details.provide_us_with_information')}
          </Text>
        </Box>
        <Box w={{ base: '100%', lg: '67%' }}>
          <MyAccountForm
            responsive
            actionLabel={t('my_account.details.update_my_details')}
            userProfile={userProfile}
          />
        </Box>
      </Flex>
      <ChangePassword />
    </Box>
  );
};

export default DetailsView;
