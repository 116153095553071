import { Button, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from 'utils/tracking/hooks';
import { generatePath, Link as ReactLink } from 'react-router-dom';
import { PAGE_PATHS } from 'config/routes';
import { ESSENTIAL_TO_SMART_UPGRADE } from 'config/channels';

import bg_mobile from 'assets/images/upsell-bg-mobile.png';
import bg from 'assets/images/upsell-bg.png';

export default function PlanUpsell() {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const handleDiscoverClick = () => {
    trackCtaClick('upgrade now', 'essential to smart landing page');
  };

  return (
    <VStack
      textAlign="center"
      py={{
        base: 4,
        md: 20,
      }}
      px={{
        base: 4,
        md: 0,
      }}
      backgroundColor="primary.700"
      backgroundImage={{
        base: `url(${bg_mobile})`,
        md: `url(${bg})`,
      }}
      borderRadius="lg"
      backgroundPosition="center"
      backgroundSize="cover"
      width="100%"
    >
      <Text color="white" fontSize="xl" fontWeight="700">
        {t('discover_smart.take_advantage')}
      </Text>
      <Text color="white" fontSize="md" mb={4}>
        {t('discover_smart.take_care_of_you', {
          amount: 100,
        })}
      </Text>
      <Button
        variant="primaryAlt"
        onClick={handleDiscoverClick}
        as={ReactLink}
        to={{
          pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
          search: `?channel=${ESSENTIAL_TO_SMART_UPGRADE}&upgrade=true`,
        }}
      >
        {t('discover_smart.cta')}
      </Button>
    </VStack>
  );
}
