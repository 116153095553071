import { Order } from 'models/Order';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { ApiQueryOptions } from 'hooks/api/queryHelper';
import {
  getPaymentConfirmation,
  GetPaymentConfirmationResponse,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { plainToClass } from 'class-transformer';

const useStripeOrder = ({
  paymentIntentId,
  options,
}: {
  paymentIntentId: string;
  options?: ApiQueryOptions<GetPaymentConfirmationResponse>;
}) => {
  return useProtectedQuery({
    queryKey: ['stripeOrder', paymentIntentId],
    queryFn: () =>
      getPaymentConfirmation({
        client: ahplusClient,
        params: { id: paymentIntentId },
      }),
    select: (order) => plainToClass(Order, order.order),
    ...options,
  });
};

export default useStripeOrder;
