import AirBundlePage from 'pages/Journey/AirBundlePages/AirBundlePage';
import { InsuranceType } from '@airhelp/plus';

export const AirBundleRoutes = {
  path: 'journey/:id',
  children: [
    {
      id: 'air-payout',
      path: 'air-payout/*',
      element: <AirBundlePage insuranceType={InsuranceType.AIR_PAYOUT} />,
    },
    {
      id: 'air-luggage',
      path: 'air-luggage/*',
      element: <AirBundlePage insuranceType={InsuranceType.AIR_LUGGAGE} />,
    },
  ],
};
