import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import DashboardLogoLink from 'elements/DashboardLogoLink';
import LanguageSwitcher from 'elements/LanguageSwitcher';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

import UserAction from './UserAction';
import TopBanners from 'components/Layouts/Default/TopBanners';

const Header: React.FC = () => {
  const { userProfile } = useMyProfile();

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showPaymentManagementBanner = Boolean(
    userProfile?.policy.membership?.showPaymentManagementBanner,
  );

  const showTopBanner =
    showTopBannerEssentialUpgrade || showPaymentManagementBanner;

  const getContentMarginTop = (base?: boolean) => {
    const bannerCount =
      Number(showTopBannerEssentialUpgrade) +
      Number(showPaymentManagementBanner);

    const baseOffset = base ? 48 : 64;

    switch (bannerCount) {
      case 2:
      case 1:
        return `${bannerCount * baseOffset}px`;
      default:
        return '0px';
    }
  };

  const getDashboardLogoMarginTop = () => {
    const bannerCount =
      Number(showTopBannerEssentialUpgrade) +
      Number(showPaymentManagementBanner);

    switch (bannerCount) {
      case 2:
        return '40';
      case 1:
        return '20';
      default:
        return '10';
    }
  };

  return (
    <Box position="relative" width="100%">
      {showTopBanner ? (
        <TopBanners
          showTopBannerEssentialUpgrade={showTopBannerEssentialUpgrade}
          showPaymentManagementBanner={showPaymentManagementBanner}
        />
      ) : null}
      <Box
        as="header"
        height={{ base: '48px', lg: '64px' }}
        position={{ base: 'fixed', lg: 'absolute' }}
        width="100%"
        zIndex="10"
        backgroundColor={{ base: 'greyscale.300', lg: 'transparent' }}
        mt={{
          base: getContentMarginTop(true),
          md: getContentMarginTop(),
          lg: getContentMarginTop(),
        }}
      >
        <Box
          position={{ base: 'absolute', lg: 'fixed' }}
          top={0}
          ms={{ base: 4, lg: 10 }}
          mt={{ base: 2, lg: getDashboardLogoMarginTop() }}
        >
          <DashboardLogoLink />
        </Box>
        <Flex
          height="100%"
          alignItems="center"
          justifyContent="flex-end"
          pe={{ base: 4, lg: 8 }}
          ps={4}
        >
          <LanguageSwitcher />
          <UserAction />
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
